<template>
  <v-container>
<!--    <v-row>-->
<!--      <v-col cols="10">-->
<!--        <p class="display-1 text&#45;&#45;primary">{{$i18n.t("")}}</p>-->
<!--      </v-col>-->
<!--      <v-col cols="2">-->
<!--        <v-btn text color="primary" large @click="readAllNotification">{{$i18n.t("Read All")}}</v-btn>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row v-if="this.$store.state.notifications.length !== 0">-->
<!--      <v-col cols="12">-->
<!--        <v-list>-->
<!--          <v-list-item v-for="(item, i) in this.$store.state.notifications" :key="i">-->
<!--            <v-list-item-content>-->
<!--              <v-card flat :class="$vuetify.theme.dark ? 'grey darken-3' : 'blue lighten-5'" >-->
<!--                <v-card-title v-if="item.sender != null">From {{ item.sender.firstName }} {{ item.sender.lastName }}</v-card-title>-->
<!--                <v-card-subtitle>{{ new Date(item.createDate).toLocaleString() }}</v-card-subtitle>-->
<!--                <v-card-text>-->
<!--                  {{ item.content }}-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--            </v-list-item-content>-->
<!--            <v-list-item-action>-->
<!--              <v-btn icon @click="readNotification(item._id)">-->
<!--                <v-icon>-->
<!--                  mdi-close-->
<!--                </v-icon>-->
<!--              </v-btn>-->
<!--            </v-list-item-action>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row justify="center" v-else>-->
<!--      <v-col cols="12">-->
<!--        <v-img src="../assets/email-read.svg" style="color: #484848" max-width="200" class="mx-auto my-6"></v-img>-->
<!--      </v-col>-->
<!--      <v-col cols="12">-->
<!--        <h2 class="text-center">{{$i18n.t("There is no unread notification")}}</h2>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col cols="4" class="lighten-3 grey rounded">
        <v-list nav class="rounded fill-height">
          <v-list-item-group v-model="selectedUserId">
            <v-list-item v-for="sender in senders" :key="sender.userProfiles._id" :value="sender.userProfiles._id">
              <v-list-item-avatar>
                <v-img :src="sender.userProfiles.photoUrl"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                  <v-list-item-title class="justify-space-between">
                    <v-row>
                      <v-col>
                        <div>
                          {{ sender.userProfiles.firstName }} {{ sender.userProfiles.lastName }}
                        </div>
                      </v-col>
                      <v-col class="d-flex justify-end" v-if="sender.unreadCount>0">
                        <v-chip class="rounded-10 white--text justify-end" color="red">
                          {{sender.unreadCount}}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                <v-list-item-subtitle>
                  {{sender.newestNotification.content}}
                </v-list-item-subtitle>
              </v-list-item-content>
<!--              <v-list-item-action>-->
<!--                <v-btn icon @mouseover="hoverDeleteId = sender._id" @mouseleave="hoverDeleteId = undefined">-->
<!--                  <v-icon>-->
<!--                    {{hoverDeleteId !== undefined && hoverDeleteId === sender._id ? 'mdi-delete-empty' : 'mdi-delete'}}-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
<!--              </v-list-item-action>-->
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <ChatDialog v-if="selectedUserId" :receiverId="selectedUserId" @read="clearChip"></ChatDialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import user from "@/utils/User";
import ChatDialog from "@/components/ChatDialog.vue";

export default {
  name: "Notifications",
  components: {ChatDialog},
  data() {
    return {
      senders: [],
      selectedUserId: undefined,
      hoverDeleteId: undefined
    }
  },
  created() {
    user.getNotificationSenderList((res) => {
      for (const each of res){
        this.senders.push(each)
      }
    })
  },
  mounted() {
    this.$store.state.loading = false;
  },
  methods: {
    readNotification(id) {
      user.readNotification(id, () => {
        this.$store.state.notifications.map((each, index) => {
          if (id === each._id) {
            this.$store.state.notifications.splice(index, 1)
          }
        })
      })
    },
    clearChip(id){
      this.senders.forEach(each => {
        if (each.userProfiles._id === id){
          each.unreadCount = 0
        }
      })
    }
  }
}
</script>

<style scoped>
.red-point{
  content: " ";
  border: 3px solid red;/*设置红色*/
  border-radius:3px;/*设置圆角*/
  position: absolute;
  z-index: 1000;
  right: 0%;
  margin-right: -5px;
  margin-top: -5px;
}
</style>
