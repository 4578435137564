<template>
  <v-container>
    <v-row>
      <span class="text-h6">{{$i18n.t("Basic Details")}}</span>
    </v-row>
    <v-row>
      <p>{{$i18n.t("Course Cover")}}</p>
    </v-row>
    <v-row>
      <v-col v-if="!formData.coverUrl">
        <v-btn
            text id="no-background-hover"
            width="200"
            class="pt-16 pb-16"
            v-if="!selectingCover && !formData.coverUrl"
            @click="selectingCover = !selectingCover"
            :ripple="false"
        >
          <v-img src="../../assets/select-file.svg" max-height="200px" max-width="200px"/>
        </v-btn>
        <v-overlay v-if="selectingCover">
          <picture-uploader :stencil-props="stencilProps" :upload="uploadCover"/>
        </v-overlay>
      </v-col>
      <v-col cols="12" v-if="formData.coverUrl" lg="4">
        <v-img
            :src="formData.coverUrl"
            :alt="$i18n.t('Selected Thumbnail')"
            max-height="200px"
            max-width="200px"
            class="mb-3"
        />
        <v-btn @click="reselectTumb">{{$i18n.t("Reselect")}}</v-btn>
      </v-col>
      <v-col cols="12" lg="8">
        <v-row>
          <v-text-field
              :label="$i18n.t('Course Name*')"
              v-model="formData.name"
              required
          ></v-text-field>
        </v-row>
        <v-row>
          <v-select
              :items="category"
              :label="$i18n.t('Category*')"
              v-model="formData.category"
              required
          ></v-select>
        </v-row>
        <v-row>
          <v-select
              :items="userList"
              :item-text="formData.instructor.firstName ? 'userDetails.firstName':'firstName'"
              item-value="userDetails"
              :hint="`${formData.instructor.firstName} ${formData.instructor.lastName}, ${formData.instructor._id}`"
              :label="$i18n.t('Instructor*')"
              v-model="formData.instructor"
              persistent-hint
              required
          ></v-select>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div>{{$i18n.t("Description*")}}</div>
        <RichEditor @setText="setDesText" :editor-text="formData.desText"/>
<!--        <v-textarea-->
<!--            label="Description*"-->
<!--            v-model="formData.desText"-->
<!--            required-->
<!--        ></v-textarea>-->
      </v-col>
      <v-col cols="12">
        <v-textarea
            :label="$i18n.t('Sub-Description*')"
            v-model="formData.desSub"
            auto-grow
            required
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PictureUploader from "@/components/PictureUploader";
import RichEditor from "@/components/RichEditor";
import user from "@/utils/User";
export default {
  name: "CourseDetailsForm",
  props: ["formData"],
  components: {PictureUploader, RichEditor},
  data() {
    return {
      selectingCover: false,
      category: [
        {text: this.$i18n.t('Speaking'), value: 0},
        {text: this.$i18n.t('Writing'), value: 1},
        {text: this.$i18n.t('Listening'), value: 2},
        {text: this.$i18n.t('Reading'), value: 3},
      ],
      stencilProps: {
        aspectRatio: 1
      },
      userList: [],
      teacher: undefined,
      options: {
        page: 1,
        itemsPerPage: 10000,
        sortBy: ['']
      }
    }
  },
  created() {
    this.doSearch()
    if (this.formData){
      this.teacher = this.formData.instructor
    }
  },
  methods: {
    uploadCover(resultUrl) {
      this.formData.coverUrl = resultUrl;
      this.selectingCover = false
    },
    reselectTumb() {
      this.formData.coverUrl = null
    },
    setDesText(text){
      this.formData.desText = text
    },
    doSearch() {
      user.getUserListAdmin(this, "auths.authorities", "teacher")
    },
  }
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
