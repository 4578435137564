import axios from "@/utils/axios";
import api from "../store/api.js"
import snackBar from "@/utils/SnackBar";

function getHomeworkQueue(limit, page, func){
    axios.get(
        api.EXE_GET_UNFINISHED_WORK + '?limit='+limit+'&page='+page+'&sort=1'
    ).then(res => {
        func(res.data.data)
    })
}

function getExerciseDetail(exerciseId, func){
    axios.get(
        api.EXE_GET_EXE + '?exercise_id='+exerciseId
    ).then(res => {
        func(res.data.data)
    })
}

function getDocument(docId, func){
    axios.get(
        api.EXE_GET_DOC+'?doc_id='+docId
    ).then(res => {
        func(res.data.data)
    })
}

function getHomeworkById(homeworkId, func){
    axios.get(
        api.EXE_GET_HOMEWORK + '?queue_id='+ homeworkId
    ).then(res => {
        func(res.data.data)
    })
}

function pickHomework(homeworkId, email, func){
    axios.post(
        api.EXE_PICK_HOMEWORK + '?queue_id='+homeworkId
    ).then(res => {
        if (res.data.code === 4008){
            snackBar.Launch(this.$i18n.t("No more homework to mark!"))
        }
        func(res.data.data)
    })
}

function getOneUnfinishedWritingHomework(func){
    axios.get(
        api.EXE_PICK_WRITING_HOMEWORK
    ).then(res => {
        if (res.data.code === 4008){
            snackBar.Launch(this.$i18n.t("No more homework to mark!"))
        }
        func(res.data.data)
    })
}

function getOneOnholdWritingHomework(func){
    axios.get(
        api.EXE_PICK_ONHOLD_WRITING_HOMEWORK
    ).then(res => {
        if (res.data.code === 4008){
            snackBar.Launch(this.$i18n.t("No more homework to mark!"))
        }
        func(res.data.data)
    })
}

function getOneUnfinishedSpeakingHomework(func){
    axios.get(
        api.EXE_PICK_SPEAKING_HOMEWORK
    ).then(res => {
        if (res.data.code === 4008){
            snackBar.Launch(this.$i18n.t("No more homework to mark!"))
        }
        func(res.data.data)
    })
}

function getQuestionDetails(questionId, func){
    axios.get(
        api.EXE_GET_QUESTION + '?question_id='+questionId
    ).then(res => {
        func(res.data.data)
    })
}

function submitHomeworkReview(homeworkId, result, func){
    axios.post(
        api.EXE_SUBMIT_REVIEW + '?queue_id='+homeworkId,
        result
    ).then(res => {
        func(res.data.data)
    })
}

function updateHomeworkReview(homeworkId, result, func){
    axios.post(
      api.EXE_UPDATE_FINISHED_WORK + '?queue_id='+homeworkId,
      result
    ).then(res => {
        func(res.data.data)
    })
}

function discardHomeworkReview(homeworkId, func){
    axios.post(
        api.EXE_DISCARD_REVIEW + '?queue_id=' + homeworkId
    ).then(()=>{
        func()
    })
}

function getAllFinishedWritingHomework(limit, page, sort, func){
    axios.get(
      api.EXE_GET_FINISHED_WRITING_LIST+'?limit='+limit+'&page='+page+'&sort='+sort
    ).then(res=>{
        func(res.data)
    })
}

function onholdHomeworkReview(homeworkId, func){
    axios.post(
        api.EXE_ONHOLD_REVIEW + '?queue_id=' + homeworkId
    ).then(()=>{
        func()
    })
}

function getExerciseList(group, page, limit, sort, func){
    let filter = '?page='+page+'&limit='+limit;
    if (group.length !== 0){
        filter += '&group='+group[0]
    }
    if (sort.length !== 0){
        filter += '&sort='+sort[0]
    }else{
        filter += '&sort=0'
    }
    axios.get(
        api.EXE_GET_EXERCISE_LIST + filter,
    ).then(res => {
        func(res.data.data)
    })
}

function addExercise(data, func) {
    axios.post(
        api.EXE_ADD_EXERCISE,
        data
    ).then(() => {
        func()
    })
}

function addQuestion(exerciseId, data, func){
    axios.post(
        api.EXE_ADD_QUESTION + '?exercise_id='+exerciseId,
        data
    ).then(res => {
        func(res.data.data)
    })
}

function removeQuestion(exerciseId, questionId, func){
    axios.post(
        api.EXE_REMOVE_QUESTION + '?exercise_id='+exerciseId+'&question_id='+questionId
    ).then(() => {
        func()
    })
}

function removeExercise(courseId, exerciseId, func){
    axios.post(
        api.EXE_REMOVE_EXERCISE + '?exercise_id='+exerciseId+'&course_id='+courseId
    ).then(() => {
        func()
    })
}

function updateQuestion(data, func){
    axios.post(
        api.EXE_UPDATE_QUESTION,
        data
    ).then(()=>{
        func()
    })
}

function updateExercise(data, func){
    axios.post(
        api.EXE_UPDATE_EXERCISE,
        data
    ).then(()=>{
        func()
    })
}

function getExerciseQuestions(exerciseId, func){
    axios.get(
        api.EXE_GET_QUESTIONS+'?exercise_id='+exerciseId
    ).then(res => {
        func(res.data.data)
    })
}

function showHomework(queueId){
    axios.post(
      api.EXE_SHOW_HOMEWORK + '?queue_id=' + queueId
    )
}

function unshowHomework(queueId){
    axios.post(
      api.EXE_UNSHOW_HOMEWORK + '?queue_id=' + queueId
    )
}

export default {
    getHomeworkQueue,
    getExerciseDetail,
    getDocument,
    getHomeworkById,
    pickHomework,
    getOneUnfinishedWritingHomework,
    getOneOnholdWritingHomework,
    getOneUnfinishedSpeakingHomework,
    getQuestionDetails,
    submitHomeworkReview,
    discardHomeworkReview,
    getExerciseList,
    addExercise,
    addQuestion,
    removeQuestion,
    removeExercise,
    updateQuestion,
    updateExercise,
    onholdHomeworkReview,
    getExerciseQuestions,
    getAllFinishedWritingHomework,
    updateHomeworkReview,
    showHomework,
    unshowHomework
}
