<template>
  <v-container>
    <v-row v-if="formData.modules.length === 0">
      <v-col>
        <p class="text-h5">{{$i18n.t("There is no module in this course.")}}</p>
        <small>{{$i18n.t("Add one to upload video or you can skip this for now.")}}</small>
      </v-col>
    </v-row>
    <v-row class="pb-3">
      <v-expansion-panels popout>
        <v-expansion-panel v-for="(module, i) in formData.modules" :key="i">
          <v-expansion-panel-header>{{ module.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <p>{{$i18n.t("Module Name")}}</p>
                <v-text-field v-model="module.title"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-3">
              <v-expansion-panels popout>
                <v-expansion-panel v-for="(lesson, i) in module.lessons" :key="i">
                  <v-expansion-panel-header>{{ lesson.name }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-row>
                            <p class="pl-3">{{$i18n.t("Lesson Name")}}</p>
                            <v-spacer/>
                            <v-btn class="error" @click="deleteLesson(module, lesson)">Delete</v-btn>
                          </v-row>
                          <v-text-field v-model="lesson.name"></v-text-field>
                          <VideoUploader :lesson="lesson"/>
                          <v-checkbox v-model="lesson.free" :label="$i18n.t('Free Trail Lesson')"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row>
              <v-btn @click="addLesson(module)">{{$i18n.t("Add Video")}}</v-btn>
              <v-spacer/>
              <v-btn @click="deleteModule(module)" class="error">{{$i18n.t("Remove module")}}</v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class="bt-3 pb-3">
      <v-col>
        <v-btn block @click="addModule">{{$i18n.t("Add a module")}}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoUploader from "@/components/VideoUploader";
import snackBar from "@/utils/SnackBar";
export default {
  name: "CourseModuleForm",
  props: ["formData"],
  components: {VideoUploader},
  data() {
    return {
    }
  },
  methods: {
    addModule() {
      this.formData.modules.push({
        lessons: [],
        number: this.formData.modules.length + 1,
        title: 'New Module',
        archived: false
      })
    },
    addLesson(module) {
      module.lessons.push({
        free: false,
        groupName: module.title,
        length: 0,
        name: "New Lesson",
        subtitles: [],
        url: "",
        archived: false
      })
    },
    deleteModule(module) {
      this.formData.modules.map((item, i) => {
        if (item === module) {
          this.formData.modules.splice(i, 1)
        }
      })
      snackBar.Launch(module.title + ' has been deleted!')
    },
    deleteLesson(module, lesson) {
      module.lessons.map((item, i) => {
        if (item === lesson) {
          module.lessons.splice(i, 1)
        }
      })
      snackBar.Launch(lesson.name + ' has been deleted!')
    }
  }
}
</script>

<style scoped>

</style>
