import Vue from 'vue'
import VueRouter from 'vue-router'
import tokenUtils from "@/utils/token";

import Home from '@/views/Home.vue'
import Course from "@/views/Course";
import Auth from "@/views/Admin";
import Member from "@/views/Member";
import Personal from "@/views/Personal";
import Homework from "@/views/Homework";
import Portal from "@/views/Portal";
import Notifications from "@/views/Notifications";

import Questions from "@/views/Questions";
// import Staffs from "@/views/Staffs";
import user from "@/utils/User";
import store from "@/store";
import DailyPost from "@/views/DailyPost";
import WriteDocument from "@/views/WriteDocument";
import snackBar from "@/utils/SnackBar";
import NewCourseForm from "@/views/NewCourseForm";
import EmailValidationPage from "@/views/EmailValidationPage.vue";
import i18n from "@/i18n";
import Statistic from "@/views/Statistic.vue";
import UserNotification from "@/components/user/UserNotification.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home',
            page: i18n.t('Home'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/portal',
        name: 'Portal',
        component: Portal,
        meta: {
            title: 'Portal',
            page: i18n.t('Portal'),
            requiresAuth: false,
            auth: ["normal", "teacher", "admin", "god"]
        }
    },
    {
        path: '/course/manage',
        name: 'Course',
        component: Course,
        meta: {
            title: 'Course Management',
            page: i18n.t('Course Management'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/course/work',
        name: 'Homework',
        component: Homework,
        meta: {
            title: 'Homework Review',
            page: i18n.t('Homework Review'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/course/add',
        name: 'New Course',
        component: NewCourseForm,
        meta: {
            title: 'New Course',
            page: i18n.t('New Course'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Auth,
        meta: {
            title: 'Admin Panel',
            page: i18n.t('Admin Panel'),
            requiresAuth: true,
            auth: ["admin", "god"]
        }
    },
    {
        path: '/personal',
        name: 'Personal',
        component: Personal,
        meta: {
            title: 'Personal Info',
            page: i18n.t('Personal Info'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/members',
        name: 'Members',
        component: Member,
        meta: {
            title: 'Member Management',
            page: i18n.t('Member Management'),
            requiresAuth: true,
            auth: ["admin", "god"]
        }
    },
    {
        path: '/members/notify',
        name: 'Members Notification',
        component: UserNotification,
        meta: {
            title: 'Member Notification',
            page: i18n.t('Member Notification'),
            requiresAuth: true,
            auth: ["admin", "god"]
        }
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notifications,
        meta: {
            title: 'Notifications',
            page: i18n.t('Notifications'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/statistic',
        name: 'Statistic',
        component: Statistic,
        meta: {
            title: 'Statistic',
            page: i18n.t('Statistic'),
            requiresAuth: true,
            auth: ["admin", "god"]
        }
    },
    {
        path: '/course/questions',
        name: 'Questions Management',
        component: Questions,
        meta: {
            title: 'Questions',
            page: i18n.t('Questions Management'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/post',
        name: 'Daily Post Management',
        component: DailyPost,
        meta: {
            title: 'Daily Post',
            page: i18n.t('Daily Post Management'),
            requiresAuth: true,
            auth: ["teacher", "admin", "god"]
        }
    },
    {
        path: '/document',
        name: 'Document Editor',
        component: WriteDocument,
        meta: {
            title: 'Document Editor',
            page: i18n.t('Document Editor'),
            requiresAuth: true,
            auth: ["normal"]
        }
    },
    {
        path: '/email/validation',
        name: 'Email Validation',
        component: EmailValidationPage,
        meta: {
            title: 'Email Validation',
            page: i18n.t('Email Validation'),
            requiresAuth: false,
            auth: ["normal"]
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
const arrHasSameValue = (arr1, arr2) => {
    return arr1.filter(item => {
        if (arr2.indexOf(item) > -1) {
            return item;
        }
    })
}
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Ielts-daily'
    }
    const token = localStorage['access_token']
    //根据路由标记，判断此请求是否需要登陆
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //需要登陆
        if (token) {
            //token存在，放行
            const auths = tokenUtils.getAuthority();
            if(arrHasSameValue(auths, to.meta.auth).length > 0){
                next()
            }else {
                next({path: '/portal', query: {redirect: to.fullPath}})
                snackBar.Launch(i18n.t("You do not have permission to access the page."))
            }
        } else {
            //token不存在，跳转登陆页面
            next({path: '/portal', query: {redirect: to.fullPath}})
        }
    } else {
        next()
    }
})

router.afterEach(() => {
        user.getUnreadNotification(res => {
            res.map((item, index, array) => {
                if (!item.systemNotification) {
                    user.getUserPublicDetails(item.senderId, sender => {
                        item.sender = sender
                    })
                }
                if (index === array.length - 1) {
                    store.state.notifications = res
                }
            })
        })
    }
)

export default router
