function getAuthority(){
    if (localStorage.getItem('access_token') === null || localStorage.getItem('access_token') === 'null'){
        return [];
    }
    let payload = JSON.parse(new Buffer(localStorage.getItem('access_token').split('.')[1], 'base64').toString())
    return payload.authorities
}

function getAuthority2(token){
    if (token === null || token === 'null'){
        return [];
    }
    let payload = JSON.parse(new Buffer(token.split('.')[1], 'base64').toString())
    return payload.authorities
}


export default {getAuthority, getAuthority2}
