<template>
  <v-container>
    <v-row class="bt-3 ">
      <v-btn
          text
          @click="closeDialog"
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{$i18n.t("Back")}}
      </v-btn>
      <v-spacer/>
      <v-btn
          color="primary"
          @click="addCourse"
          :disabled="!(formData.name !== '' &&
          formData.coverUrl !== '' &&
          formData.category !== null &&
          formData.desText !== '')"
      >
        {{$i18n.t("Submit")}}
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-overlay
            absolute
            :value="loading"
        >
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </v-overlay>
        <v-row>
          <v-col cols="12" lg="6">
            <v-card>
              <v-card-text>
                <CourseDetailsForm :form-data="formData"/>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card>
              <v-card-text>
                <CourseModuleForm :form-data="formData"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!--          <v-stepper-content step="3">-->
        <!--            <v-container>-->
        <!--              <CourseExerciseForm :form-data="formData"/>-->
        <!--              <v-row class="bt-3 ">-->
        <!--                <v-btn-->
        <!--                    text-->
        <!--                    @click="step -= 1"-->
        <!--                >-->
        <!--                  Back-->
        <!--                </v-btn>-->
        <!--                <v-spacer/>-->
        <!--                <v-btn-->
        <!--                    color="primary"-->
        <!--                    @click="addCourse"-->
        <!--                >-->
        <!--                  {{ formData.excises.length === 0 ? 'Skip and Submit' : 'Submit' }}-->
        <!--                </v-btn>-->
        <!--              </v-row>-->
        <!--            </v-container>-->
        <!--          </v-stepper-content>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "@/utils/firebase";
import course from "@/utils/Course"
import store from "@/store";
import CourseModuleForm from "@/components/course/CourseModuleForm";
import CourseDetailsForm from "@/components/course/CourseDetailsForm";
// import CourseExerciseForm from "@/components/CourseExerciseForm";

export default {
  name: "CourseForm",
  components: {
    CourseModuleForm,
    CourseDetailsForm,
    // CourseExerciseForm
  },
  data() {
    return {
      dialog: false,
      loading: false,
      step: 1,
      formData: {
        name: '',
        coverUrl: '',
        category: null,
        desText: '',
        desSub: '',
        instructor: store.state.user,
        modules: [],
        excises: [],
        archived: false
      },
    }
  },
  mounted() {
    this.$store.state.loading = false
  },
  methods: {
    clearFormData() {
      this.step = 1
      this.formData = {
        name: '',
        coverUrl: '',
        category: null,
        desText: '',
        desSub: '',
        instructor: store.state.user,
        modules: [],
        excises: [],
        archived: false
      }
    },
    closeDialog() {
      this.clearFormData()
      this.$router.back()
    },
    addCourse() {
      this.loading = true
      if (!this.check()){
        return;
      }
      course.addNewCourse(this.formData, () => {
        this.loading = false
        this.$router.back()
        this.clearFormData()
      })
    },
    check(){
      return this.formData.name !== '' &&
          this.formData.coverUrl !== '' &&
          this.formData.category !== null &&
          this.formData.desText !== ''
    }
  },
  watch: {
    "selectedCoverFile": function () {
      firebase.getBase64(this.selectedCoverFile, (image) => {
        this.selectCoverImage = image
      })
    }
  }
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
