<template>
  <v-container class="pt-10">
    <v-card
        class="mt-4 mx-auto"
    >
      <v-sheet
          class="v-sheet--offset mx-auto mb-5"
          color="cyan"
          elevation="12"
          max-width="calc(100% - 32px)"
      >
        <GChart type="LineChart" :data="activeUserGraph.chartData" :options="activeUserGraph.chartOptions">
        </GChart>
      </v-sheet>
      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-light mb-2">
          Daily Active Users
        </div>
      </v-card-text>
    </v-card>
    <v-card
        class="mt-4 mx-auto"
    >
      <v-sheet
          class="v-sheet--offset mx-auto mb-5"
          color="cyan"
          elevation="12"
          max-width="calc(100% - 32px)"
      >
        <GChart type="LineChart" :data="newUserGraph.chartData" :options="newUserGraph.chartOptions">
        </GChart>
      </v-sheet>
      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-light mb-2">
          Daily New Users
        </div>
      </v-card-text>
    </v-card>
    <v-card
        class="mt-4 mx-auto"
    >
      <v-sheet
          class="v-sheet--offset mx-auto mb-5"
          color="cyan"
          elevation="12"
          max-width="calc(100% - 32px)"
      >
        <GChart type="LineChart" :data="paidUserGraph.chartData" :options="paidUserGraph.chartOptions">
        </GChart>
      </v-sheet>
      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-light mb-2">
          Daily Paid Users
        </div>
      </v-card-text>
    </v-card>
    <v-card
        class="mt-4 mx-auto"
    >
      <v-sheet
          class="v-sheet--offset mx-auto mb-5"
          color="white"
          elevation="12"
          max-width="calc(100% - 32px)"
      >
        <v-container>
          <v-row>
            <GChart
                class="pt-4 pl-4"
                type="Calendar"
                :data="homeworkFinishedGraph.chartData"
                :options="homeworkFinishedGraph.chartOptions"
                :settings="homeworkFinishedGraph.settings"
            />
          </v-row>
        </v-container>
      </v-sheet>
      <v-card-text class="pt-0">
        <div class="text-h6 font-weight-light mb-2">
          Daily Homework Count
        </div>
      </v-card-text>
    </v-card>
<!--    <v-card-->
<!--        class="mt-4 mx-auto"-->
<!--    >-->
<!--      <v-sheet-->
<!--          class="v-sheet&#45;&#45;offset mx-auto mb-5"-->
<!--          color="white"-->
<!--          elevation="12"-->
<!--          max-width="calc(100% - 32px)"-->
<!--      >-->
<!--        <v-container>-->
<!--          <v-row>-->
<!--            <GChart-->
<!--                class="pa-4 pl-4"-->
<!--                type="GeoChart"-->
<!--                :data="geoChart.chartData"-->
<!--                :options="geoChart.chartOptions"-->
<!--                :settings="geoChart.settings"-->
<!--            />-->
<!--          </v-row>-->
<!--        </v-container>-->
<!--      </v-sheet>-->
<!--      <v-card-text class="pt-0">-->
<!--        <div class="text-h6 font-weight-light mb-2">-->
<!--          World User Distribution-->
<!--        </div>-->
<!--      </v-card-text>-->
<!--    </v-card>-->
  </v-container>
</template>

<script>
import statistic from "@/utils/statistic";
import {GChart} from "vue-google-charts/legacy";

export default {
  name: "Statistic",
  components: {GChart},
  data() {
    return {
      activeUserGraph: {
        chartData: [],
        chartOptions: {
          title: 'Daily Active Users',
        }
      },
      newUserGraph: {
        chartData: [],
        chartOptions: {
          title: 'Daily New Users',
        }
      },
      paidUserGraph: {
        chartData: [],
        chartOptions: {
          title: 'Daily Paid Users',
        }
      },
      homeworkFinishedGraph: {
        chartData: [
          [
            {
              type: 'date',
              id: 'Date',
            },
            {
              type: 'number',
              id: 'Homework Count',
            },
          ],
        ],
        chartOptions: {
          title: 'Daily Homework Count',
          calendar: {cellSize: 15},
          width: 1000,
          height: 330,
          colorAxis: {
            colors: ['#9cffb1', '#005014']
          }
        },
        settings: {
          packages: ['calendar'],
        },
      },
      geoChart: {
        type: 'GeoChart',
        chartData: [
          ['Country', 'Popularity'],
        ],
        chartOptions: {
          width: 900,
          height: 700,
        },
        settings: {
          packages: ['geochart'],
        },
      }
    }
  },
  created() {
    this.getLast7daysUserActive();
    this.getLast7daysUserNew();
    this.getLast7daysUserPaid();
    this.getAllUserHomeworkStatistic();
    this.getGeoLocation();
  },
  methods: {
    getLast7daysUserActive() {
      statistic.getDailyActiveUsers(6, 0
        , res => {
          const result = res.data.map(item => {
            if (item && item.date) {
              const date = new Date(item.date);
              return [date.toLocaleDateString(), item.userIds ? item.userIds.length : 0];
            } else {
              return ['', 0];
            }
          });
          result.unshift(['date', 'Active User Count']);
          this.activeUserGraph.chartData = result
        })
    },
    getLast7daysUserNew() {
      statistic.getDailyNewUsers(6, 0
        , res => {
          const result = Object.keys(res.data).sort().map((key) => {
            if (key) {
              const date = new Date(parseInt(key));
              return [date.toLocaleDateString(), res.data[key]];
            } else {
              return ['', 0];
            }
          });
          result.unshift(['date', 'New User Count']);
          this.newUserGraph.chartData = result
        })
    },
    getLast7daysUserPaid() {
      statistic.getDailyPaidUsers(6, 0
          , res => {
            const result = Object.keys(res.data).sort().map((key) => {
              if (key) {
                const date = new Date(parseInt(key));
                return [date.toLocaleDateString(), res.data[key]];
              } else {
                return ['', 0];
              }
            });
            result.unshift(['date', 'Paid User Count']);
            if (result.length === 1){
              // if result is empty, add last 6 days as 0, start from the earliest date
              const now = new Date();
              for (let i = 6; i >= 0; i--) {
                const pastDate = new Date(now); // 复制当前日期对象
                pastDate.setDate(now.getDate() - i); // 设置为过去的日期
                const localizedDate = pastDate.toLocaleDateString(); // 获取本地化日期字符串
                result.push([localizedDate, 0]); // 将本地化日期字符串添加到列表中
              }
            }
            this.paidUserGraph.chartData = result
          })
    },
    getAllUserHomeworkStatistic() {
      statistic.getAllUserHomeworkStatistic(res => {
        const result = Object.keys(res.data).map((key) => {
          if (key) {
            const date = new Date(parseInt(key));
            return [date, res.data[key]];
          }
        });
        this.homeworkFinishedGraph.chartData.push.apply(this.homeworkFinishedGraph.chartData, result)
      })
    },
    getGeoLocation(){
      statistic.getUserGeoLocation(res => {
        const result = Object.keys(res.data).map((key) => {
          if (key) {
            return [key, res.data[key]];
          }
        });
        this.geoChart.chartData.push.apply(this.geoChart.chartData, result)
      })
    }
  },
  mounted() {
    this.$store.state.loading = false;
  },
}
// function getPast7DaysLocalizedDates() {
//   var past7Days = [];
//   var now = new Date(); // 获取当前日期
//   for (var i = 6; i >= 0; i--) {
//     var pastDate = new Date(now); // 复制当前日期对象
//     pastDate.setDate(now.getDate() - i); // 设置为过去的日期
//     var localizedDate = pastDate.toLocaleDateString(); // 获取本地化日期字符串
//     past7Days.push(localizedDate); // 将本地化日期字符串添加到列表中
//   }
//   return past7Days;
// }
</script>

<style scoped>

</style>
