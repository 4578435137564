var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.userCanReview)?_c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.$i18n.t("You should register a Gmail Account and fill your gmail account to your profile before marking!")))])]):(!_vm.homeworkSelected || !this.$store.state.marking)?_c('v-container',{staticClass:"ma-auto"},[_c('v-row',{staticClass:"ma-auto",attrs:{"justify":"center"}},[_c('h1',[_vm._v(_vm._s(_vm.$i18n.t("Choose one entry to start marking")))])]),_c('v-row',{staticClass:"ma-auto",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mr-lg-5",attrs:{"cols":"12","lg":"3"}},[_c('v-row',[_c('v-col',[_c('v-col',[_c('PictureBtn',{attrs:{"height":_vm.canSee() ? '240' : '300',"max-width":"150","img":require('../assets/writing-notepad.svg'),"label":_vm.$i18n.t('Writing Homework'),"on-click":_vm.pickWriting}})],1),(_vm.canSee)?_c('v-col',[_c('v-btn',{staticClass:"info",attrs:{"block":""},on:{"click":_vm.pickOnhold}},[_vm._v(_vm._s(_vm.$i18n.t("On Hold Homeworks")))])],1):_vm._e()],1)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"ml-lg-5",attrs:{"cols":"12","lg":"3"}},[_c('PictureBtn',{attrs:{"height":"300","max-width":"170","img":require('../assets/girl-speaking.svg'),"label":_vm.$i18n.t('Speaking Homework'),"disabled":""}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$i18n.t('Writing Homework Review History')))])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-data-table',{staticClass:"elevation-6",attrs:{"items":_vm.homeworkList,"headers":_vm.headers.filter(function (item){ return item.invisible!==true; }),"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"item-key":"userDetails._id","server-items-length":_vm.totalItems,"sort-desc":_vm.desc,"footer-props":{
                itemsPerPageOptions: [10, 20, 50],
          }},on:{"update:items":function($event){_vm.homeworkList=$event},"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.desc=$event},"update:sort-desc":function($event){_vm.desc=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showHomework(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.showHomework ? 'mdi-eye' : 'mdi-eye-off')+" ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.reviewHomework(item.id)}}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"item.createTime",fn:function(ref){
          var item = ref.item;
return [(item.createTime)?_c('div',[_vm._v(" "+_vm._s(new Date(item.createTime).toLocaleString())+" ")]):_vm._e()]}},{key:"item.finishTime",fn:function(ref){
          var item = ref.item;
return [(item.finishTime)?_c('div',[_vm._v(" "+_vm._s(new Date(item.finishTime).toLocaleString())+" ")]):_vm._e()]}}])})],1)],1):_vm._e(),(_vm.homeworkSelected && _vm.userCanReview && !this.$store.state.loading && this.$store.state.marking)?_c('HomeworkReview',{attrs:{"homework":_vm.homeworkSelected}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }