import axios from "@/utils/axios";
import api from "@/store/api";

function getUserHomeworkFinished(userId, time, func){
  axios.post(
    api.STATISTIC_USER_HOMEWORK_FINISHED+"?userId="+userId,
    time
  ).then(r => {
    func(r.data)
  })
}

function getUserHomeworkStatistic(userId, func){
  axios.get(
    api.STATISTIC_USER_HOMEWORK_DAILY+"?userId="+userId
  ).then(r => {
    func(r.data)
  })
}

function getAllUserHomeworkStatistic(func){
  axios.get(
    api.STATISTIC_USER_HOMEWORK_DAILY
  ).then(r => {
    func(r.data)
  })
}

function getDailyActiveUsers(start, finish, func){
  axios.get(
    api.STATISTIC_DAILY_ACTIVE+"?start="+start+"&finish="+finish,
  ).then(r => {
    func(r.data)
  })
}

function getDailyNewUsers(start, finish, func){
  axios.get(
    api.STATISTIC_DAILY_NEW+"?start="+start+"&finish="+finish,
  ).then(r => {
    func(r.data)
  })
}

function getDailyPaidUsers(start, finish, func){
  axios.get(
    api.STATISTIC_DAILY_PAID+"?start="+start+"&finish="+finish,
  ).then(r => {
    func(r.data)
  })
}

function getMidnightTimestamp(daysAgo) {
  // 获取当前时间对象
  var now = new Date();

  // 获取当前时间的年、月、日
  var year = now.getFullYear();
  var month = now.getMonth();
  var day = now.getDate();

  // 使用当前时间减去指定的天数
  var targetDate = new Date(year, month, day - daysAgo);

  // 将目标日期的小时、分钟、秒和毫秒设置为零
  targetDate.setHours(0);
  targetDate.setMinutes(0);
  targetDate.setSeconds(0);
  targetDate.setMilliseconds(0);

  // 获取目标日期的时间戳（单位：毫秒）
  var timestamp = targetDate.getTime();

  return timestamp;
}

function getUserGeoLocation(func){
  axios.get(
    api.STATISTIC_USER_GEOLOCATIONS
  ).then(r => {
    func(r.data)
  })
}

export default {
  getUserHomeworkFinished,
  getUserHomeworkStatistic,
  getDailyActiveUsers,
  getDailyPaidUsers,
  getMidnightTimestamp,
  getDailyNewUsers,
  getAllUserHomeworkStatistic,
  getUserGeoLocation
}
