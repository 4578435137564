<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="icon"
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon style="color: #f32121">
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
            v-else-if="block"
            v-bind="attrs"
            block
            v-on="on"
            class="error"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
          {{$i18n.t("Remove")}}
        </v-btn>
        <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            class="error"
        >
          <v-icon>
            mdi-delete
          </v-icon>
          {{$i18n.t("Remove")}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{$i18n.t("Attention")}}
        </v-card-title>

        <v-card-text>
          {{$i18n.t("Are you sure to delete?")}}<br>{{$i18n.t("This action cannot re-do!")}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="deleteMethod"
          >
            {{$i18n.t("Delete")}}
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            {{$i18n.t("Cancel")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeleteBtn",
  data() {
    return {
      dialog: false
    }
  },
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteMethod() {
      this.$emit("confirm")
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
