<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert type="info" dismissible>{{$i18n.t("Type your answer.")}}<br/>
          {{$i18n.t("Click the 'Save' button in the top right corner.")}}<br/>
          {{$i18n.t("Go back to the IELTS daily app on your phone.")}}<br/>
          {{$i18n.t("Click 'Submit' on the app.")}}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="11" md="10" sm="10" class="d-flex justify-end">
        <v-card class="d-flex align-center transparent" height="40" elevation="0" :color="length > wordLimit ? 'red':'white'">
          <v-card-text :class="length > wordLimit ? 'red' : ''">
            <v-icon v-if="length > wordLimit">mdi-alert-circle-outline</v-icon>
            {{$i18n.t("Word count")}}: {{length}} / {{wordLimit}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="1" md="2" sm="2" class="d-flex justify-end">
        <v-btn @click="save" class="success" v-if="onPc">{{$i18n.t("Save")}}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="d-flex justify-start">
        <div v-if="onPc" v-html="toText(question)"></div>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex justify-center" v-if="onPc">
        <v-textarea v-model="data" solo  auto-grow rows="30" @input="countWords(data)"/>
      </v-col>
      <v-alert type="error" v-else>{{$i18n.t("You don’t have permission to edit this document.")}}<br/>
        <br/>
        {{$i18n.t("To edit the document;")}}<br/>
        {{$i18n.t("go to the IELTS daily app,")}}<br/>
        {{$i18n.t("choose the writing question again,")}}<br/>
        {{$i18n.t("click on the link to type on computer.")}}
      </v-alert>
    </v-row>
    <v-row>

    </v-row>
  </v-container>
</template>

<script>
import api from "@/store/api";
import exercise from "@/utils/exercise";
import snackBar from "@/utils/SnackBar";
import axios from "@/utils/axios";

export default {
  name: "WriteDocument",
  data() {
    return {
      docId: "",
      question: "",
      wordLimit: 350,
      data: "",
      onPc: false,
      length: 0
    }
  },
  created() {

  },
  mounted() {
    this.docId = this.$route.query.docId
    const questionId = this.$route.query.question
    getDocumentOnPc(this.docId, res => {
      this.onPc = res
      if (res) {
        getRowDocument(this.docId, res => {
          this.data = res
          exercise.getQuestionDetails(questionId, res => {
            this.question = res.text
            this.wordLimit = res.part === 1 ? 350 : 600;
            this.countWords(this.data)
            this.$store.state.loading = false
          })
        })
      }else {
        this.$store.state.loading = false
      }
    })
  },
  methods: {
    save() {
      updateDocument(this.docId, this.data)
    },
    toText(str) {
      str = str.replace(/ /g, "+")
      return atob(decodeURI(str))
    },
    countWords(text){
      // 替换中文字符为空格
      text = text.replace(/[\u4e00-\u9fa5]+/g, " ");
      // 替换换行符为空格
      text = text.replace(/\n+/g, " ");
      // 将换行符，前后空格不计算为单词数
      text = text.replace(/\n|\r|^\s+|\s+$/gi,"");
      // 多个空格替换成一个空格
      text = text.replace(/\s+/gi," ");
      // 更新计数
      var length = 0;
      var match = text.match(/\s/g);
      if (match) {
        length = match.length + 1;
      } else if (text) {
        length = 1;
      }
      this.length = length
    }
  }
}

function getDocumentOnPc(docId, func) {
  axios.get(
      api.EXE_GET_DOC_IS_ON_PC + '?doc_id=' + docId
  ).then(res => {
    func(res.data.data)
  })
}

function getRowDocument(docId, func) {
  axios.get(
      api.EXE_GET_DOC_RAW + '?doc_id=' + docId
  ).then(res => {
    func(res.data.data)
  })
}

function updateDocument(docId, content) {
  axios.post(
      api.EXE_UPDATE_DOC + '?doc_id=' + docId,
      {
        "content": content
      }
  ).then((res) => {
    if (res.data.code === 200){
      snackBar.Launch("Save succeed!")
    }else {
      snackBar.Launch(res.data.message)
    }
  })
}
</script>

<style scoped>

</style>
