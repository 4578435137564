<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col block>
            <p class="text-center">{{$i18n.t("Login")}}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-card flat>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field :label="$i18n.t('Username')" filled v-model="username" persistent-hint hint="eg: +61 2930 432 / admin@gamil.com"
                            :disabled="loading"></v-text-field>
            </v-row>
            <v-row>
              <v-text-field :label="$i18n.t('Password')" filled type="password" v-model="password"
                            :disabled="loading"></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col>
                <v-btn x-large outlined elevation="2" block color="blue darken-1" text
                       @click="loading = true" :loading="loading" :disabled="loading">
                  <v-icon left>mdi-check</v-icon>
                  {{ $i18n.t('Login') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import user from "@/utils/User";
export default {
  name: "LoginForm",
  data() {
    return {
        loading: false,
        username: '',
        password: '',
    }
  },
  watch: {
    "loading": function () {
      if (this.loading) {
        this.$Login.login(this.username, this.password, ()=>{
          user.getUserDetails()
          this.$router.push(this.$route.query.redirect  || '/')
          this.loading = false
        })
      }
    }
  },
}
</script>

<style scoped>

</style>
