<template>
  <div>
<!--    <v-row>-->
<!--      <v-col xs="12" lg="6">-->
<!--        <v-card class="mx-auto elevation-6 card-translate-up">-->
<!--          <v-card-text>-->
<!--            <div>IELTS daily Teacher Platform</div>-->
<!--            <p class="display-1 text&#45;&#45;primary">Welcome! {{ $store.state.user.firstName }}-->
<!--              {{ $store.state.user.lastName }}</p>-->
<!--            <div class="text&#45;&#45;primary">-->
<!--              Site is under construction!-->
<!--            </div>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--        <v-card class="mx-auto mt-3 elevation-6 card-translate-up">-->
<!--          <v-card-title>-->
<!--            <h2>Portals</h2>-->
<!--          </v-card-title>-->
<!--          <v-card-text class="mt-5">-->
<!--            -->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col xs="12" lg="6">-->
<!--        <TodoList/>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-container>
      <v-row>
        <p class="text-h4">{{$i18n.t("Quick Functions")}}</p>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4">
          <PictureBtn height="200" max-width="50" :img="require('../assets/exam.svg')" :label="$i18n.t('mark homework')" :on-click="gotoMarkWork"/>
        </v-col>
        <v-col cols="12" lg="4">
          <PictureBtn height="200" max-width="50" :img="require('../assets/video-editor.svg')" :label="$i18n.t('course management')" :on-click="gotoCourseManage"/>
        </v-col>
        <v-col cols="12" lg="4">
          <PictureBtn height="200" max-width="50" :img="require('../assets/homework.svg')" :label="$i18n.t('exercise management')" :on-click="gotoExerciseManage"/>
        </v-col>
        <v-col cols="12" lg="4">
          <PictureBtn height="200" max-width="50" :img="require('../assets/password.svg')" :label="$i18n.t('change password')" :on-click="gotoPersonalInfo"/>
        </v-col>
        <!--              <v-col cols="12" lg="4">-->
        <!--                <PictureBtn height="150" max-width="50" :img="require('../assets/homework.svg')" label="mark homework" disabled/>-->
        <!--              </v-col>-->
        <!--              <v-col cols="12" lg="4">-->
        <!--                <PictureBtn height="150" max-width="50" :img="require('../assets/homework.svg')" label="mark homework" disabled/>-->
        <!--              </v-col>-->
      </v-row>
    </v-container>

  </div>
</template>

<script>
import anime from 'animejs';
// import TodoList from "@/components/TodoList";
import store from "@/store";
import PictureBtn from "@/components/PictureBtn";

export default {
  name: 'Home',
  components: {PictureBtn},
  data() {
    return {
      reveal: false,
      creatingNewTodo: false,
      toDoList: []
    }
  },
  created() {
  },
  methods: {
    gotoMarkWork() {
      this.$router.push({path: '/course/work'})
    },
    gotoCourseManage() {
      this.$router.push({path: '/course/manage'})
    },
    gotoExerciseManage() {
      this.$router.push({path: '/course/questions'})
    },
    gotoPersonalInfo() {
      this.$router.push({path: '/personal'})
    }
  },
  mounted() {
    setTimeout(() => {
      try {
        anime({
          targets: '.card-translate-up',
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(75),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-translate-up h1', '.card-translate-up h2', '.card-translate-up p', '.card-translate-up span:not(.v-btn__content)'],
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(25),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-fade-appear'],
          scaleY: [1.1, 1],
          duration: 325,
          easing: "easeOutQuint"
        })
      } catch (e) {
        console.warn("HomeAnimation", "error when animating home entry animation", e)
      }
    }, 0);
    store.state.loading = false;
  }
}
</script>
<style>
</style>
