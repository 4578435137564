export default {
    // 基础API地址

    // 测试
    // BASE_URL: 'https://test-api.ielts-daily.com',

    // 生产环境
    BASE_URL: 'https://api.ielts-daily.com',
    OAUTH_URL: 'https://sso.ielts-daily.com',

    // 后台API使用凭证
    CLIENT_ID: 'portal',
    CLIENT_SECRET: 'QDWqwdqWDqwd23rf2w4Gv4wrgvWEgbvhr5',

    // 登录相关路径
    AUTH_LOGIN: '/oauth/token',
    UPDATE_PASSWORD: '/auth/update-password',
    USER_BLOCK: '/auth/block-user',
    GET_USER_ACCOUNT_STATE: '/auth/account',
    UPDATE_USER_AUTH: '/auth/update-authority',
    UPDATE_USER_ACCOUNT: '/auth/update-account',
    GENERATE_USER: '/auth/generate-account',

    // 课程相关路径
    COURSE_ADD: '/course/add',
    GET_COURSE_LIST_BY_INSTRUCTOR: "/course/getCourseListByInstructorId",
    ARCHIVE_COURSE: '/course/remove',
    ACTIVE_COURSE: '/course/active',
    GET_ALL_COURSE: '/course/list',
    COURSE_GET: '/course/',
    UPDATE_COURSE: '/course/update',

    // 用户相关路径
    GET_USER: '/user/getUser',
    GET_USER_INFO: '/user/info-data',
    UPDATE_USER: '/user/update',
    USER_ADMIN_LIST: '/user/admin/list/v2',
    USER_UPDATE_EMAIL: '/user/update-email',
    USER_UPDATE_MOBILE: '/user/update-mobile',
    USER_GET_STATES: '/user/states',
    USER_GET_STATES_ADMIN: '/user/states/admin',
    USER_UPDATE_STATES: '/user/states/update',
    USER_GET_UNREAD_NOTIFICATIONS: '/user/get-notification/unread',
    USER_GET_ALL_NOTIFICATIONS: '/user/get-notification/all',
    USER_READ_NOTIFICATION: '/user/read-notification',
    USER_SEND_NOTIFICATION: '/user/group/send-notification',
    USER_GET_USER_NOTIFICATION_SENDER_LIST: '/user/get-notification/sender',
    USER_GET_USER_NOTIFICATION_SENDER_LIST_V2: '/user/v2/get-notification/sender',
    GET_USER_PROFILE_DATA: '/user/profile-data',
    USER_TOKEN_NUMBER: '/user/admin/token',
    UPDATE_USER_TOKEN_NUMBER: '/user/admin/update/token',
    UPDATE_USER_COURSE: '/user/admin/update/course',
    EXPORT_USER_CSV: '/user/admin/printer/userList',

    // 作业相关路径
    EXE_GET_EXE: '/course/exercise',
    EXE_GET_QUESTIONS: '/course/exercise/questions',
    EXE_GET_DOC: '/course/get/doc',
    EXE_GET_DOC_RAW: '/course/get/doc/raw',
    EXE_GET_DOC_IS_ON_PC: '/course/get/doc/isOnPc',
    EXE_GET_HOMEWORK: '/course/get/work',
    EXE_GET_UNFINISHED_WORK: '/course/get/work/unfinished',
    EXE_PICK_HOMEWORK: '/course/work/pick',
    EXE_PICK_WRITING_HOMEWORK: '/course/get/work/unfinished/writing',
    EXE_PICK_ONHOLD_WRITING_HOMEWORK: '/course/get/work/onhold/writing',
    EXE_PICK_SPEAKING_HOMEWORK: '/course/get/work/unfinished/speaking',
    EXE_GET_QUESTION: '/course/question',
    EXE_SUBMIT_REVIEW: '/course/work/finish',
    EXE_DISCARD_REVIEW: '/course/work/unfinish',
    EXE_ONHOLD_REVIEW: '/course/work/onhold',
    EXE_GET_EXERCISE_LIST: '/course/exercise/list',
    EXE_ADD_EXERCISE: '/course/add/exercise',
    EXE_ADD_QUESTION: '/course/add/question',
    EXE_REMOVE_QUESTION: '/course/remove/question',
    EXE_REMOVE_EXERCISE: '/course/remove/exercise',
    EXE_UPDATE_QUESTION: '/course/update/question',
    EXE_UPDATE_EXERCISE: '/course/update/exercise',
    EXE_UPDATE_DOC: '/course/doc/update',
    EXE_GET_FINISHED_WRITING_LIST: '/course/get/work/finished/writing',
    EXE_UPDATE_FINISHED_WORK: '/course/work/update',
    EXE_SHOW_HOMEWORK: '/course/work/show',
    EXE_UNSHOW_HOMEWORK: '/course/work/unshow',

    // Vimeo视频上传
    VIMEO_BASE_URL: 'https://api.vimeo.com',
    VIMEO_CLIENT_ID: '7161f75611cbc086aa7ab4650c1a0b74c4b822da',
    VIMEO_CLIENT_SECRET: 'GyVVw1GZ8NVQfM3YIZ5gcsQ6IivMX8NdctkKW6GfNtEcw8PmDn7Iea+nrfr46nR227i3rsT+CSHcxxoT5D9cUrFnIqEl3viKMI6mYvbqp6DuCs+6UO+2BWNd5buaEF1v',
    VIMEO_PRIVATE_ACCESS_TOKEN: '2459fd5db39835353df28239d012a987',

    //DailyPost相关
    DAILYPOST_ADD: '/dailypost/add',
    DAILYPOST_DELETE: '/dailypost/delete',
    DAILYPOST_UPDATE: '/dailypost/update',
    DAILYPOST_GET_ONE: '/dailypost/',
    DAILYPOST_GET_LIST: '/dailypost/list',

    //Order相关
    ORDER_HISTORY: '/order/history',

    //统计相关
    STATISTIC_DAILY_ACTIVE: '/statistics/dailyLogin',
    STATISTIC_DAILY_NEW: '/statistics/dailyNew',
    STATISTIC_DAILY_PAID: '/statistics/dailyPaid',
    STATISTIC_USER_HOMEWORK_DAILY: '/statistics/homework/daily',
    STATISTIC_USER_HOMEWORK_FINISHED: '/statistics/homework',
    STATISTIC_USER_GEOLOCATIONS: '/statistics/geoLocations',
}
