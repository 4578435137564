import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import vuescroll from 'vuescroll'
import i18n from './i18n'
import store from './store'
import VueResource from 'vue-resource'
import '@mdi/font/css/materialdesignicons.css'
import './assets/css/global.css'

import SnackBar from "@/utils/SnackBar";
import Login from "@/utils/Login";
import GetUser from "@/utils/User";
import StorageUsage from "@/utils/StorageUsage";
import Init from "@/utils/Init";
import Key from "@/utils/Key";
import axios from "@/utils/axios";

import VueGoogleCharts from 'vue-google-charts/legacy'
import VueVirtualScroller from 'vue-virtual-scroller'


Vue.prototype.$snackbar = SnackBar
Vue.prototype.$Login = Login
Vue.prototype.$GetUser = GetUser
Vue.prototype.$StorageUsage = StorageUsage
Vue.prototype.$Init = Init
Vue.prototype.$Key = Key
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VueVirtualScroller)
Vue.use(VueResource)
Vue.use(VueGoogleCharts)
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      sizeStrategy: "number",
      wheelScrollDuration: 200
    },
    scrollPanel: {
      scrollingX: false,
      easing: 'easeOutQuad',
      padding: true,
    },
    rail: {
      gutterOfSide: '1px',
    },
    bar: {
      background: '#ffffff',
      opacity: 0.5,
      size: '5px',
    }
  },
})

new Vue({
  router,
  vuetify,
  i18n,
  store,
  VueResource,
  render: h => h(App),
}).$mount('#app')

router.beforeEach(
    (to, from, next)=> {
      store.state.loading = true;
      next();
    });

router.afterEach(
    ()=> {
      setTimeout(()=> {
        store.state.loading = false;
      }, 5000)
    }
)
