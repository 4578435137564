<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          large
          text
          id="no-background-hover"
          class="pt-16 pb-16"
          :ripple="false"
      >
        <v-hover v-slot:default="{ hover }">
          <v-img
              :src="$store.state.user.photoUrl === 'null' ? require('../assets/profile.png') : $store.state.user.photoUrl"
              lazy-src="../assets/profile.png"
              :alt="$store.state.user.firstName +' '+ $store.state.user.lastName"
              style="border-radius: 50%"
              max-height="200px"
              max-width="200px"
              contain
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
            <v-overlay
                v-show="hover"
                absolute
            >
              <v-icon>mdi-camera</v-icon>
              {{$i18n.t("Edit")}}
            </v-overlay>
          </v-img>
        </v-hover>
      </v-btn>
    </template>
    <PictureUploader :upload="upload" :stencil-props="stencilProps"/>
  </v-dialog>
</template>

<script>
import PictureUploader from "@/components/PictureUploader";
import user from "@/utils/User";

export default {
  components: {PictureUploader},
  name: 'avatarCropper',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      stencilProps: {
        aspectRatio: 1
      }
    }
  },

  methods: {
    // 读取原图
    upload(result) {
      this.$store.state.user.photoUrl = result
      user.updateUserDetails(this.$store.state.user)
    },
    // 关闭弹框
    closeDialog() {
      this.options.imageFile = null
    },
  },
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
