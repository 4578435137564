<template>
  <div>
    <v-dialog width="500px" v-model="dialog" persistent>
      <v-card>
        <v-card-title/>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center" style="opacity: 1">
              <h1>{{$i18n.t("Verify Phone Number")}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert colored-border  color="info">
                {{$i18n.t("Please enter your 6 digits code below")}}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center grey darken-1">
              <vue-vercode ref="vercode"></vue-vercode>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="6" sm="12">
              <v-btn color="error" block @click="cancel" :loading="loading" :disabled="loading">
                <v-icon left>mdi-cancel</v-icon>
                {{$i18n.t("Cancel")}}
              </v-btn>
            </v-col>
            <v-col lg="6" sm="12">
              <v-btn color="success" block @click="verify" :disabled="loading || showtime">
                <v-icon left>mdi-check</v-icon>
                {{ showtime ? showtime : $i18n.t("OK")}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn @click="open" block class="warning" height="56">
      <v-icon left>mdi-email-edit-outline</v-icon>
      {{$i18n.t("Verify")}}
    </v-btn>
  </div>
</template>

<script>
import VueVercode from "@auspicious/vue-vercode";
import verify from "@/utils/verify";

export default {
  components: {VueVercode},
  name: "Email",
  data() {
    return {
      dialog: false,
      loading: false,
      timeCounter: null,
      showtime: null
    }
  },
  props: ["phoneNumber"],
  methods: {
    async verify() {
      this.loading = true
      await verify.verifyPhoneNumber(this, this.phoneNumber, this.$refs.vercode.getCode())
    },
    async open() {
      this.dialog = true
      await verify.verifyPhoneNumber(this, this.phoneNumber)
    },
    cancel() {
      this.dialog = false
    },
    countDownText(s) {
      this.showtime = s + 's'
    },
    countDown(times) {
      const self = this;
      const interval = 1000;
      let count = 0;
      self.timeCounter = setTimeout(countDownStart, interval);

      function countDownStart() {
        if (self.timeCounter == null) {
          return false;
        }
        count++
        self.countDownText(times - count + 1);
        if (count > times) {
          clearTimeout(self.timeCounter)
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval)
        }
      }
    },
    send() {
      this.loading = true
      this.$Email.send(this)
    }
  },
}
</script>

<style scoped>

</style>
