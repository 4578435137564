<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.askingDialog.enable" persistent max-width="420">
      <v-card class="pa-5">
        <v-card-title class="pb-5">
          <v-row class="justify-center">
            <p>{{ $store.state.askingDialog.text }}</p>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-btn @click="yes">{{$i18n.t("YES")}}</v-btn>
            <v-spacer/>
            <v-btn @click="no">{{$i18n.t("NO")}}</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {}
  },
  methods: {
    no() {
      this.$store.state.askingDialog.enable = false
      this.$store.state.askingDialog.func(false)
    },
    yes() {
      this.$store.state.askingDialog.enable = false
      this.$store.state.askingDialog.func(true)
    },
  }
}
</script>

<style scoped>

</style>
