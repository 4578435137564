<template>
  <v-container>
    <v-container v-if="!userCanReview">
      <h1>{{$i18n.t("You should register a Gmail Account and fill your gmail account to your profile before marking!")}}</h1>
    </v-container>
    <v-container v-else-if="!homeworkSelected || !this.$store.state.marking" class="ma-auto">
      <v-row justify="center" class="ma-auto">
        <h1>{{$i18n.t("Choose one entry to start marking")}}</h1>
      </v-row>
      <v-row justify="center" class="ma-auto">
        <v-col cols="12" lg="3" class="mr-lg-5">
          <v-row>
            <v-col>
              <v-col>
                <PictureBtn :height="canSee() ? '240' : '300'" max-width="150"
                            :img="require('../assets/writing-notepad.svg')" :label="$i18n.t('Writing Homework')"
                            :on-click="pickWriting"/>
              </v-col>
              <v-col v-if="canSee">
                <v-btn block class="info" @click="pickOnhold">{{$i18n.t("On Hold Homeworks")}}</v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical/>
        <v-col cols="12" lg="3" class="ml-lg-5">
          <PictureBtn height="300" max-width="170" :img="require('../assets/girl-speaking.svg')"
                      :label="$i18n.t('Speaking Homework')" disabled/>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <div class="title">{{$i18n.t('Writing Homework Review History')}}</div>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-data-table
            :items.sync="homeworkList"
            :headers="headers.filter(item=>item.invisible!==true)"
            class="elevation-6"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="options"
            item-key="userDetails._id"
            :server-items-length="totalItems"
            :sort-desc.sync="desc"
            :footer-props="{
                  itemsPerPageOptions: [10, 20, 50],
            }"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="showHomework(item)">
              <v-icon>
                {{item.showHomework ? 'mdi-eye' : 'mdi-eye-off'}}
              </v-icon>
            </v-btn>
            <v-btn icon @click="reviewHomework(item.id)">
              <v-icon>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.createTime="{ item }">
            <div v-if="item.createTime">
              {{ new Date(item.createTime).toLocaleString() }}
            </div>
          </template>
          <template v-slot:item.finishTime="{ item }">
            <div v-if="item.finishTime">
              {{ new Date(item.finishTime).toLocaleString() }}
            </div>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <HomeworkReview :homework="homeworkSelected"
                    v-if="homeworkSelected && userCanReview && !this.$store.state.loading && this.$store.state.marking"/>
  </v-container>
</template>

<script>
import HomeworkReview from "@/components/HomeworkReview";
import exercise from "@/utils/exercise";
import user from "@/utils/User";
import pager from '../assets/paper.png'
import PictureBtn from "@/components/PictureBtn";
import token from "@/utils/token";
import i18n from "@/i18n";

export default {
  name: "Homework",
  components: {HomeworkReview, PictureBtn},
  data() {
    return {
      userCanReview: this.$store.state.user.email !== null && this.$store.state.user.email.endsWith("@gmail.com"),
      homeworkSelected: null,
      homeworkQueue: [],
      paper: pager,
      webHeight: document.body.clientHeight,
      desc: true,
      page: 1,
      totalItems: 10,
      itemsPerPage: 10,
      loading: true,
      homeworkList: [],
      options:{},
      headers: [
        {text: i18n.t("Title"), value: 'exerciseName', align: 'start', sortable: false},
        {text: i18n.t('User'), value: 'userName', align: 'start', sortable: false},
        {text: i18n.t('Teacher'), value: 'teacherName', align: 'start', sortable: false},
        {text: i18n.t('Create Date'), value: 'createTime', align: 'start', sortable: false},
        {text: i18n.t('Finish Date'), value: 'finishTime', align: 'start', sortable: false},
        {text: i18n.t('Rate'), value: 'rate', align: 'start', sortable: false},
        {text: i18n.t('Actions'), value: 'actions', sortable: false, filterable: false},
      ],
    }
  },
  created() {
    this.$store.state.loading = true
    user.getUserState((res) => {
      if (res.homeworkReviewing !== undefined) {
        exercise.getHomeworkById(res.homeworkReviewing, (homework) => {
          exercise.getExerciseDetail(homework.exerciseId, exercisesDetail => {
            homework.exercise = exercisesDetail
            homework.exercise.questions.map((item, index) => {
              exercise.getQuestionDetails(item, questionDetail => {
                if (homework.exercise.questionDetails === undefined) {
                  homework.exercise.questionDetails = []
                }
                questionDetail.text = atob(questionDetail.text)
                homework.exercise.questionDetails.push(questionDetail)
                if (index === homework.exercise.questions.length - 1) {
                  user.getUserEnhanceInfo(homework.userId, userDetail => {
                    homework.user = userDetail
                    this.homeworkSelected = homework
                    this.$store.state.marking = true
                    this.$store.state.loading = false
                  })
                }
              })
            })
          })
        })
      } else {
        this.$store.state.loading = false
      }
    })
    exercise.getAllFinishedWritingHomework(10, 1,1,res=>{
      this.totalItems = res.data.count;
      this.homeworkList = res.data.data;
      this.loading = false;
    })
  },
  methods: {
    pickWriting() {
      exercise.getOneUnfinishedWritingHomework(res => {
        if (res === null) {
          return
        }
        this.pickHomework(res._id, this.$store.state.user.email)
        this.$store.state.marking = true
      })
    },
    pickOnhold() {
      exercise.getOneOnholdWritingHomework(res => {
        if (res === null) {
          return
        }
        this.pickHomework(res._id, this.$store.state.user.email)
        this.$store.state.marking = true
      })
    },
    showHomework(item){
      if (item.showHomework){
        exercise.unshowHomework(item.id)
      }else {
        exercise.showHomework(item.id)
      }
      item.showHomework = !item.showHomework
    },
    pickSpeaking() {

    },
    canSee() {
      return arrHasSameValue(["admin", "god"], token.getAuthority()).length > 0
    },
    pickHomework(homeworkId, email) {
      this.$store.state.loading = true;
      exercise.pickHomework(homeworkId, email, homework => {
        exercise.getExerciseDetail(homework.exerciseId, exercisesDetail => {
          homework.exercise = exercisesDetail
          homework.exercise.questions.map((item, index) => {
            exercise.getQuestionDetails(item, questionDetail => {
              if (homework.exercise.questionDetails === undefined) {
                homework.exercise.questionDetails = []
              }
              homework.exercise.questionDetails.push(questionDetail)
              if (index === homework.exercise.questions.length - 1) {
                user.getUserEnhanceInfo(homework.userId, userDetail => {
                  homework.user = userDetail
                  this.homeworkSelected = homework
                  this.$store.state.marking = true
                  this.$store.state.loading = false
                })
              }
            })
          })
        })
      })
    },
    reviewHomework(id){
      this.$store.state.loading = true;
      exercise.getHomeworkById(id, homework=> {
        exercise.getExerciseDetail(homework.exerciseId, exercisesDetail => {
          homework.exercise = exercisesDetail
          homework.exercise.questions.map((item, index) => {
            exercise.getQuestionDetails(item, questionDetail => {
              if (homework.exercise.questionDetails === undefined) {
                homework.exercise.questionDetails = []
              }
              homework.exercise.questionDetails.push(questionDetail)
              if (index === homework.exercise.questions.length - 1) {
                user.getUserEnhanceInfo(homework.userId, userDetail => {
                  homework.user = userDetail
                  this.homeworkSelected = homework
                  this.$store.state.marking = true
                  this.$store.state.loading = false
                })
              }
            })
          })
        })
      })
    }
  },
  mounted() {
  },
  watch: {
    "$store.state.user.email": {
      deep: true,
      handler: function () {
        this.userCanReview = this.$store.state.user.email !== null && this.$store.state.user.email.endsWith("@gmail.com")
      }
    },
    options: {
      handler() {
        if (this.options.page === undefined || this.options.itemsPerPage === undefined) {
          return;
        }
        this.homeworkList = []
        this.loading = true
        exercise.getAllFinishedWritingHomework(this.options.itemsPerPage, this.options.page,1,res=>{
          this.totalItems = res.data.count;
          this.homeworkList = res.data.data;
          this.loading = false;
        })
      },
      deep: true,
    },
  }
}
const arrHasSameValue = (arr1, arr2) => {
  return arr1.filter(item => {
    if (arr2.indexOf(item) > -1) {
      return item;
    }
  })
}
</script>

<style scoped>
</style>
