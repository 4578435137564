import axios from "@/utils/axios";
import store from "@/store";
import snackBar from "@/utils/SnackBar";
import device from "@/utils/device";
import api from "@/store/api";

function verifyEmail(email) {
    axios.post(
        api.USER_UPDATE_EMAIL,
        {email: email, phoneNumber: store.state.user.phoneNumber}
    ).then((res) => {
        snackBar.Launch(res.data.data)
    }).catch(error => {
        snackBar.Launch(error.response.message)
    })
}

function verifyPhoneNumber(that, phoneNumber, code) {
    let data = {
        phoneNumber: phoneNumber,
        deviceEntity: device.getDeviceEntity()
    }
    if (code) {
        data["code"] = code
    }
    axios.post(
        api.USER_UPDATE_MOBILE,
        data
    ).then((res)=>{
        snackBar.Launch(res.data.data)
        that.loading = false
        if (code && res.data.data === 'ok'){
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('id')
            store.state.user.status = false
            location.reload()
        }
    }).catch(res => {
        snackBar.Launch(res.response.message)
    })
}

export default {verifyEmail, verifyPhoneNumber}
