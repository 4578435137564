<template>
  <v-navigation-drawer
      app
      permanent
      v-if="$store.state.user.status
      && !isStudent
      && $route.path !== '/document'
      && $route.path !== '/email/validation'"
      id="nav"
      :mini-variant.sync="$store.state.nav.mini"
  >
    <NoticeCard/>
    <LoginDialog/>
    <UserDialog/>
    <v-divider/>
    <v-list nav>
      <div v-for="item in NavItems" :key="item.id" class="my-2">
        <v-list-group
            v-model="item.active"
            :prepend-icon="item.icon"
            v-if="item.items && canSee(item)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="subItem in item.items"
              :key="subItem.id"
              :to="item.url + subItem.url"
              dense
          >
            <v-list-item-avatar>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="subItem.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item link :to="item.url" v-if="!item.items && canSee(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NoticeCard from "@/components/NoticeCard";
import LoginDialog from "@/components/LoginDialog";
import UserDialog from "@/components/UserDialog";
import token from "@/utils/token";
import i18n from "@/i18n";

export default {
  name: "NavBar",
  components: {UserDialog, LoginDialog, NoticeCard},
  data() {
    return {
      auths: [],
      isStudent: false,
      NavItems: [
        {
          id: 1,
          active: false,
          text: i18n.t('Home'),
          icon: 'mdi-home',
          url: '/',
          auth: ["teacher", "admin", "god"]
        },
        {
          id: 2,
          active: false,
          text: i18n.t('Course'),
          icon: 'mdi-book',
          url: '/course',
          auth: ["teacher", "admin", "god"],
          items: [
            {
              id: 7,
              text: i18n.t('Course Management'),
              icon: 'mdi-circle-small',
              url: '/manage',
              auth: ["teacher", "admin", "god"]
            },
            {
              id: 9,
              text: i18n.t('Questions Management'),
              icon: 'mdi-circle-small',
              url: '/questions',
              auth: ["teacher", "admin", "god"]
            },
            {
              id: 3,
              text: i18n.t('Homework Review'),
              icon: 'mdi-circle-small',
              url: '/work',
              auth: ["teacher", "admin", "god"]
            }
          ]
        },
        {
          id: 4,
          active: false,
          text: i18n.t('Personal Info'),
          icon: 'mdi-clipboard-account',
          url: '/personal',
          auth: ["teacher", "admin", "god"]
        },
        {
          id: 8,
          active: false,
          text: i18n.t('Notifications'),
          icon: 'mdi-email',
          url: '/notification',
          auth: ["teacher", "admin", "god"]
        },
        {
          id: 5,
          active: false,
          text: i18n.t('Member'),
          icon: 'mdi-account-supervisor',
          url: '/members',
          auth: ["admin", "god"],
          // items: [
          //     {
          //         id: 10,
          //         text: 'Member Management',
          //         icon: 'mdi-circle-small',
          //         url: '/member',
          //         auth: ["admin", "god"]
          //     },
          //     {
          //         id: 11,
          //         text: 'Staff Management',
          //         icon: 'mdi-circle-small',
          //         url: '/staff',
          //         auth: ["admin", "god"]
          //     }
          // ]
        },
        {
          id: 12,
          active: false,
          text: i18n.t('Daily Post'),
          icon: 'mdi-clipboard-text',
          url: '/post',
          auth: ["teacher", "admin", "god"]
        },
        {
          id: 13,
          active: false,
          text: i18n.t('Statistic'),
          icon: 'mdi-chart-areaspline',
          url: '/statistic',
          auth: ["admin", "god"]
        },
        {
          id: 6,
          active: false,
          text: i18n.t('Admin'),
          icon: 'mdi-emoticon-devil-outline',
          url: '/admin',
          auth: ["admin", "god"]
        },
      ],
    }
  },
  watch: {
    "store.state.user.isStudent": {
      deep: true,
      handler: (val) => {
        this.isStudent = val
        this.auths = token.getAuthority();
      }
    },
    "store.state.user.token.access":{
      deep: true,
      handler: (val) => {
        this.auths = token.getAuthority2(val);
      }
    }
  },
  created() {
  },
  methods: {
    canSee(item){
      return arrHasSameValue(item.auth, token.getAuthority()).length > 0
    }
  }
}

const arrHasSameValue = (arr1, arr2) => {
  return arr1.filter(item => {
    if (arr2.indexOf(item) > -1) {
      return item;
    }
  })
}
</script>

<style scoped>
#nav {
  overflow: hidden !important;
}
</style>
