<template>
  <v-dialog v-model="$store.state.viewingUserStatistic" max-width="822px"
            onclose="$store.state.viewingUserStatistic = null">
    <v-card>
      <v-card-title class="pb-5">
        <div class="text-h5">{{ $i18n.t("User Statistic") }}</div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <div class="text-h6">{{ $i18n.t("Homework Finished") }}</div>
          </v-row>
          <v-row>
            <v-col>
              <v-icon>mdi-pencil</v-icon>
              Writing: {{userStatistic.finishedHomework.writing}}
            </v-col>
            <v-col>
              <v-icon>mdi-account-voice</v-icon>
              Speaking: {{userStatistic.finishedHomework.speaking}}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-icon>mdi-book-open</v-icon>
              Reading: {{userStatistic.finishedHomework.reading}}
            </v-col>
            <v-col>
              <v-icon>mdi-ear-hearing</v-icon>
              Listening: {{userStatistic.finishedHomework.listening}}
            </v-col>
          </v-row>
          <v-row>
            <GChart
                type="Calendar"
                :data="homeworkFinishedGraph.chartData"
                :options="homeworkFinishedGraph.chartOptions"
                :settings="homeworkFinishedGraph.settings"
            />
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="12" lg="6">
              <v-btn block @click="$store.state.viewingUserStatistic = null">{{ $i18n.t("Back") }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import statistic from "@/utils/statistic";
import {GChart} from "vue-google-charts/legacy";

export default {
  name: "UserStatistic",
  components: {GChart},
  data(){
    return {
      userStatistic: {
        finishedHomework: {
          speaking: 0,
          reading: 0,
          listening: 0,
          writing: 0,
        }
      },
      homeworkFinishedGraph: {
        chartData: [
          [
            {
              type: 'date',
              id: 'Date',
            },
            {
              type: 'number',
              id: 'Homework Count',
            },
          ],
        ],
        chartOptions: {
          title: 'Daily Homework Count',
          calendar: { cellSize: 12 },
          width: 800,
          colorAxis: {
            colors:['#9cffb1','#005014']
          }
        },
        settings: {
          packages: ['calendar'],
        },
      },
    }
  },
  created() {
    this.getStatistic()
    this.getHomeworkStatistic()
  },
  methods: {
    getStatistic(){
      let startTime = statistic.getMidnightTimestamp(3000);
      let finishTime = statistic.getMidnightTimestamp(0);
      statistic.getUserHomeworkFinished(this.$store.state.viewingUserStatistic._id, {
        startTime, finishTime
      }, res => {
        this.userStatistic = res.data
      })
    },
    getHomeworkStatistic(){
      statistic.getUserHomeworkStatistic(this.$store.state.viewingUserStatistic._id, res => {
        const result = Object.keys(res.data).map((key) => {
          if (key) {
            const date = new Date(parseInt(key));
            return [date, res.data[key]];
          }
        });
        this.homeworkFinishedGraph.chartData.push.apply(this.homeworkFinishedGraph.chartData, result)
      })
    }
  }
}
</script>

<style scoped>

</style>
