<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :height="height" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" :elevation="disabled ? 0 : hover ? 13 : 3">
      <v-row justify="center">
        <v-btn
            text
            :ripple="ripple"
            @click="onClick"
            :style="'top: '+height*0.4+'px'"
            :disabled="disabled"
        >
          <div>
            <v-img
                :src="img"
                :max-width="maxWidth"
                style="fill: #be4500"
                contain
                class="ma-auto"
            >
            </v-img>
            <strong>{{label}}</strong>
          </div>
        </v-btn>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "PictureBtn",
  props: {
    onClick: {
      default: function () {},
      type: Function
    },
    img: {
      required: true,
      type: String
    },
    label: {
      default: "",
      type: String
    },
    maxWidth: {
      required: true,
      type: String
    },
    height: {
      required: true,
      type: String
    },
    ripple: {
      default: false,
      type: Boolean
    },
    disabled: Boolean,
  }
}
</script>

<style scoped>
.v-btn::before {
  background-color: transparent;
  position: relative;
}
</style>
