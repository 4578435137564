<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card>
        <v-overlay v-show="hover" absolute>
          <v-row class="mx-auto">
            <v-col cols="12">
              <CourseEditDialog :show="showManage" :item="courseItem"/>
            </v-col>
          </v-row>
          <v-row class="mx-auto" v-if="!courseItem.archived">
            <v-col cols="12">
              <v-btn text @click="deleteCourse">
                <v-icon>mdi-delete</v-icon>
                {{$i18n.t("Archive")}}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mx-auto" v-else>
            <v-col cols="12">
              <v-btn text @click="activeCourse">
                <v-icon>mdi-upload</v-icon>
                {{$i18n.t("Active")}}
              </v-btn>
            </v-col>
          </v-row>
        </v-overlay>
        <v-img
            :src="courseItem.coverUrl"
            max-height="200px"
            @load="loading = false"
        >
          <v-skeleton-loader
              class="mx-auto"
              type="image"
              v-if="loading"
          >
          </v-skeleton-loader>
        </v-img>
        <v-skeleton-loader
            type="card-heading"
            v-if="loading"
            class="mx-auto"
        >
        </v-skeleton-loader>
        <v-card-title v-else>
          <span style="white-space:nowrap;
                     text-overflow:ellipsis;
                     overflow:hidden;
                     display:block"

          >{{ courseItem.name }}</span>
        </v-card-title>
        <v-skeleton-loader
            type="text"
            v-if="loading"
            class="ml-5 py-5"
            max-width="80"
        >
        </v-skeleton-loader>
        <v-card-text v-else>
          <div v-if="courseItem.archived">
            <v-icon>mdi-archive</v-icon>
            {{$i18n.t("Archived")}}
          </div>
          <div v-else-if="courseItem.price === undefined">
            <v-icon style="color: #be4500">mdi-alert-circle</v-icon>
            <span class="pa-2">{{$i18n.t("Pending")}}</span>
          </div>
          <div v-else>
            <v-icon style="color: green">mdi-check-circle</v-icon>
            <span class="pa-2">{{$i18n.t("Activated")}}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import course from "@/utils/Course";
import AskDialog from "@/utils/AskDialog";
import CourseEditDialog from "@/components/course/CourseEditDialog";

export default {
  name: "CoursePreview",
  components: {CourseEditDialog},
  data() {
    return {
      loading: true,
      askArchive: false,
      askActive: false,
      showManage: false,
      courseItem: this.courseEntity,
    }
  },
  props: {
    courseEntity: Object
  },
  created() {
  },
  methods: {
    deleteCourse() {
      AskDialog.lunch("Are you sure to archive this course?", (confirm) => {
        if (confirm) {
          course.archiveCourse(this.courseItem._id)
        }
        this.$emit("archive", this.courseItem)
      })
    },
    activeCourse() {
      AskDialog.lunch("Are you sure to active this course?", (confirm) => {
        if (confirm) {
          course.activeCourse(this.courseItem._id)
        }
        this.$emit("active", this.courseItem)
      })
    }
  }
}
</script>

<style scoped>

</style>
