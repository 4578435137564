<template>
  <v-card>
    <v-card-title class="pa-5">
      <span class="text-h5">{{$i18n.t("Upload Video")}}</span>
    </v-card-title>
    <v-card-text>
      <!-- 待上传视频 -->
      <div v-show="!selectedVideo && !uploaded">
        <v-file-input
            v-model="selectedVideo"
            accept="video/*"
        />
      </div>
      <!-- 已上传视频 -->
      <div v-show="selectedVideo || uploading || transcodeState || transcoding">
        <v-row>
          <v-col cols="12">
            <v-img :src="thumbnail" lazy-src="../assets/CourseCoverPlaceholder.png" max-width="200px" max-height="200px">
              <v-overlay v-if="uploading" absolute>
                <v-col>
                  <strong>{{$i18n.t("Uploading...")}}</strong>
                  <v-progress-linear rounded :value="uploadProgress" height="25">
                    <strong>{{ Math.ceil(uploadProgress) }}%</strong>
                  </v-progress-linear>
                </v-col>
              </v-overlay>
              <v-overlay v-if="!uploading && !transcodeState" absolute class="text-center">
                  <v-col>
                    <strong>{{$i18n.t("Transcoding...")}}</strong>
                    <v-progress-linear
                        color="amber"
                        height="25"
                        indeterminate
                        rounded
                    >
                    </v-progress-linear>
                  </v-col>
              </v-overlay>
            </v-img>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-show="selectedVideo">
      <v-btn @click="reupload">
        {{$i18n.t("Reselect")}}
      </v-btn>
      <v-btn @click="doUpload">
        {{$i18n.t("Upload")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VideoUploader from "@/utils/VideoUploader";
import snackBar from "@/utils/SnackBar";
export default {
  name: "VideoUploader",
  components: {},
  data() {
    return {
      selectedVideo: null,
      uploadProgress: 0,
      uploadedBytes: 0,
      totalBytes: 0,
      uploading: false,
      uploaded: false,
      thumbnail: null,
      transcodeState: false,
      transcoding: false
    }
  },
  props: {
    lesson: {
      type: Object
    }
  },
  methods: {
    doUpload() {
      // const isVideo = this.selectedVideo.type === 'video/*'
      // if (!isVideo) {
      //   return false
      // }
      console.log(this.selectedVideo)
      this.uploading = true

      VideoUploader.uploadVideo(this.selectedVideo, this.lesson.name, "", (result) => {
        this.uploading = false
        this.dialog = false
        this.uploadProgress = 0
        this.selectedVideo = null
        this.uploaded = true
        this.transcoding = true
        this.lesson.url = result
      }, (progress, uploaded, total) => {
        this.uploadProgress = progress
        this.uploadedBytes = uploaded
        this.totalBytes = total
      }, (error) => {
        this.uploading = false
        this.uploadProgress = 0
        console.log(error)
      })
    },
    // 重新上传
    reupload() {
      this.selectedVideo = null
    },
  },
  watch: {
    "lesson.url": {
      deep: true,
      handler: function () {
        this.timer = setInterval(() => {
          VideoUploader.checkTranscodeState(this.lesson.url, (transcodeState, error) => {
            if (transcodeState){
              this.transcodeState = transcodeState
              this.transcoding = true
            }else {
              if (error !== undefined) {
                clearInterval(this.timer);
                this.timer = null
                this.transcoding = true
                snackBar.Launch(error)
                console.log(error)
              }
            }
          })
        }, 1000 * 10);
      }
    },
    "transcodeState": {
      deep: true,
      handler: function (){
        clearInterval(this.timer);
        VideoUploader.getVideoLength(this.lesson.url, res => {
          this.lesson.length = res
        })
        this.timer = null
        VideoUploader.createThumbNail(this.lesson.url, "1", () => {
          VideoUploader.getThumbNails(this.lesson.url, (res) => {
            res.data.data.map((item) => {
              if (item.active){
                this.thumbnail = item.base_link
              }
            })
          })
        })
      }
    },
    "selectedVideo"(){
      if (this.selectedVideo != null){
        this.doUpload()
      }
    }
  }
}
</script>

<style scoped>

</style>
