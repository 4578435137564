<template>
  <div>
    <v-container v-show="!addDailyPost">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="1" sm="6">
          <v-btn @click="changeSort" text block large>
            <v-icon v-if="sort===0">mdi-arrow-up</v-icon>
            <v-icon v-else>mdi-arrow-down</v-icon>
            {{$i18n.t("time")}}
          </v-btn>
        </v-col>
        <v-col cols="12" class="mx-4" lg="1" sm="6">
          <v-btn large @click="showAddPost">
            <v-icon>mdi-plus</v-icon>
            {{$i18n.t("ADD")}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col align-self="end">
          <v-btn small class="mr-2" :disabled="page===1" @click="pravPage">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn small :disabled="this.postList.length < 5" @click="nextPage">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-for="item in this.postList" :key="item._id">
        <v-col cols="12">
          <v-card :height="item.length">
            <v-card-title>
              {{ item.title }}
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" lg="10">
                  {{ item.subtitle }}
                  <br/>
                  <br/>
                  {{$i18n.t("Created on")}}: {{ new Date(item.createTime).toLocaleString() }}
                  <br/>
                  {{$i18n.t("Created by")}}: {{ item.username }}
                </v-col>
                <v-col cols="12" lg="2" class="mt-n2">
                  <v-row>
                    <v-col cols="12">
                      <v-btn block class="info" @click="editPost(item)">
                        <v-icon small>mdi-pencil</v-icon>
                        {{$i18n.t("Edit")}}
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <DeleteBtn @confirm="deletePost(item)" :icon="false" :block="true"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-expand-transition>
              <div v-show="item.show">
                <v-divider></v-divider>
                <v-card-text>
                  <div v-html="getContent(item.content)">
                  </div>
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-card-actions>
              <v-btn
                  block
                  text
                  @click="showOrHidePost(item)"
              >
                <v-icon>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="addDailyPost">
      <v-btn @click="hidePostEditing" text large v-if="sort===0">
        <v-icon>mdi-arrow-left</v-icon>
        {{$i18n.t("Back")}}
      </v-btn>
      <DailyPostForm @success="hidePostEditing" :item="editingPost"/>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import dailyPost from "@/utils/DailyPost";
import DailyPostForm from "@/components/dailypost/DailyPostForm";
import DeleteBtn from "@/components/DeleteBtn";
import user from "@/utils/User";

export default {
  name: "DailyPost",
  components: {DailyPostForm, DeleteBtn},
  data() {
    return {
      page: 1,
      addDailyPost: false,
      postList: [],
      sort: 0,
      editingPost: null
    }
  },
  mounted() {
    store.state.loading = false;
  },
  created() {
    this.getPostList()
  },
  methods: {
    changeSort() {
      this.sort === 0 ? this.sort = 1 : this.sort = 0
      this.page = 1
      this.getPostList()
    },
    showAddPost() {
      this.addDailyPost = !this.addDailyPost
    },
    hidePostEditing() {
      this.addDailyPost = false
      this.editingPost = null
      this.getPostList()
    },
    getContent(text) {
      try {
        return atob(text)
      } catch (e) {
        return text
      }
    },
    showOrHidePost(item) {
      item.show = !item.show
      item.length = item.show ? "100%" : "214px"
    },
    getPostList() {
      this.postList = []
      dailyPost.getDailyPostList(5, this.page, this.sort, res => {
        res.map((item) => {
          try {
            item.show = false
            item.length = "214px"
            user.getUserPublicDetails(item.postBy, res => {
              item.username = res.firstName + " " + res.lastName
              this.postList.push(item)
            })
          } catch (e) {
            console.warn("Warning: description cannot decode by Base64")
          }
        })
      })
    },
    pravPage() {
      this.page -= 1
      this.getPostList()
    },
    nextPage() {
      this.page += 1
      this.getPostList()
    },
    editPost(item) {
      this.addDailyPost = true
      this.editingPost = item
    },
    deletePost(item) {
      dailyPost.deleteDailyPost(item._id, () => {
        this.getPostList()
      })
    },
  }
}
</script>

<style scoped>

</style>
