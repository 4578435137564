<script>
import RichEditor from "@/components/RichEditor.vue";
import User from "@/utils/User";
import store from "@/store";

export default {
  name: "UserNotification",
  data() {
    return {
      receiverNames: [],
      notification: {
        title: "",
        content: "",
        receivers: this.$route.query.receivers
      }
    }
  },
  components: {RichEditor},
  props: {
  },
  mounted() {
    store.state.loading = false;
  },
  created() {
    this.$route.query.receivers.forEach((each) => {
      User.getUserPublicDetails(each, (res) => {
        this.receiverNames.push(res)
      })
    })
  },
  methods: {
    setContent(text) {
      this.notification.content = text
    },
    sendNotification() {
      for (const i in this.selected) {
        this.notification.receivers.push(this.selected[i].userDetails._id)
      }
      User.sendNotification(this.notification, ()=>{})
      this.$router.go(-1)
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-title class="pb-5">
      <div class="text-h5">{{ $i18n.t("Edit & Send Notifications") }}</div>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="mt-n5">
          <v-col>
            <p class="text-h5">{{ $i18n.t("Receivers") }}</p>
          </v-col>
        </v-row>
        <v-row class="mt-n10">
          <v-col>
            <v-chip v-for="each in receiverNames" :key="each._id" class="ma-2" outlined>{{each.firstName + " " + each.lastName}}</v-chip>
          </v-col>
        </v-row>
        <v-row class="my-n5">
          <v-col>
            <p class="text-h5">{{ $i18n.t("Title") }}</p>
          </v-col>
        </v-row>
        <v-row class="mt-n10">
          <v-col>
            <v-text-field v-model="notification.title"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n5">
          <v-col>
            <p class="text-h5">{{ $i18n.t("Content") }}</p>
          </v-col>
        </v-row>
        <v-row class="mt-n5">
          <v-col>
            <RichEditor @setText="setContent" :editor-text="notification.content" :editorHeight="200"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="12" lg="6">
            <v-btn block @click="goBack">{{ $i18n.t("Cancel") }}</v-btn>
          </v-col>
          <v-col cols="12" sm="12" lg="6">
            <v-btn block @click="sendNotification">{{ $i18n.t("Send") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
