<template>
  <v-container id="iframe-container" class="overflow-y-auto">
    <!--    <v-container class="bottom-container"-->
    <!--                 :style="showBottom ? 'top: ' + (webHeight*0.87-360) + 'px' : 'top: ' + webHeight*0.87 + 'px'">-->
    <!--      <v-row justify="center">-->
    <!--        <v-btn text @click="hideBottom">-->
    <!--          <v-icon v-if="showBottom" style="color: grey">mdi-chevron-double-down</v-icon>-->
    <!--          <v-icon v-else style="color: grey">mdi-chevron-double-up</v-icon>-->
    <!--        </v-btn>-->
    <!--      </v-row>-->
    <!--      <transition name="card-translate-up">-->
    <!--    <v-row v-show="showBottom">-->
    <v-row v-scroll="onScroll">
      <v-col cols="12" sm="12" :lg="hideQuestion ? 0:(expandQuestion?5:3)" md="12">
        <v-row class="card-center">
          <v-col>
            <v-expand-transition>
              <v-card :style="{ top: offsetTop + 'px', height: questionHeight+'px'}"
                      v-show="!hideQuestion"
                      :class="$vuetify.theme.dark ? 'card-translate-up grey darken-3 overflow-x-auto question'
                                  : 'card-translate-up grey lighten-3 overflow-x-auto question'">
                <v-card-title>{{ $i18n.t("Exercise Details") }}</v-card-title>
                <v-card-text>
                  <label>{{ $i18n.t("Submit Date") }}: <strong>{{
                      new Date(homework.createDate).toLocaleString()
                    }}</strong></label>
                  <br/>
                  <label>{{ $i18n.t("Student Name") }}: <strong>{{ homework.user.firstName }} {{
                      homework.user.lastName
                    }}</strong></label>
                  <br/>
                  <label>{{ $i18n.t("Country") }}: <strong>{{ homework.user.country }}</strong></label>
                  <br/>
                  <label>{{ $i18n.t("Target Score") }}: <strong>{{ homework.user.targetScore }}</strong></label>
                  <br/>
                  <label>{{ $i18n.t("Bought Courses") }}:
                    <v-chip v-if="boughtCourses.length === 0" disabled>{{ $i18n.t("None") }}</v-chip>
                    <span v-else>
                                      <v-chip v-for="(item, i) in this.boughtCourses"
                                              :key="i">{{ getCourseChip(item) }}</v-chip>
                                    </span>
                  </label>
                  <br/>
                  <label>{{ $i18n.t("Exercise Topic") }}: <strong>{{ homework.exercise.name }}</strong></label>
                  <br/>
                  <label>{{ $i18n.t("Question") }}: <strong
                      v-html="getText(homework.exercise.questionDetails[0].text)"/></label>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn v-if="!expandQuestion" @click="expandQuestion = true">
                    <v-icon>mdi-arrow-expand</v-icon>
                    expand
                  </v-btn>
                  <v-btn v-else @click="expandQuestion = false">
                    <v-icon>mdi-arrow-collapse</v-icon>
                    collapse
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
            <v-btn class="question"
                   :style="{ top: offsetTop + 10 + 'px' }"
                   :block="!hideQuestion"
                   @click="hideQuestion = !hideQuestion"
            >
              <v-icon>{{ !hideQuestion ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
              {{ !hideQuestion ? 'hide question' : 'show question' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" :lg="hideQuestion ? 12:(expandQuestion?7:9)" md="12">
        <v-row class="card-center">
          <v-col class="d-flex justify-center">
            <iframe :src="'https://docs.google.com/document/d/'+homework.googleDocId+'/edit'"
                    class="trend-container1" id="iframe" :style="'height: '+webHeight*0.9+'px'">
            </iframe>
          </v-col>
        </v-row>
        <v-row class="card-center">
          <v-col>
            <v-card
                :class="$vuetify.theme.dark ? 'card-translate-up grey darken-3' : 'card-translate-up grey lighten-3'"
                elevation="24">
              <v-card-title>
                <v-row>
                  <v-col>
                    <span>{{ $i18n.t("Rate") }}</span>
                  </v-col>
                  <v-spacer/>
                  <v-col>
                    <span>{{ $i18n.t("Total Rate") }}: <strong>{{ finalRate }}</strong></span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-tabs
                    v-model="tab"
                    fixed-tabs
                    background-color="transparent"
                >
                  <v-tab>
                    {{ ratingTexts[homework.homeworkType].rate1.snap }}
                  </v-tab>
                  <v-tab>
                    {{ ratingTexts[homework.homeworkType].rate2.snap }}
                  </v-tab>
                  <v-tab>
                    {{ ratingTexts[homework.homeworkType].rate3.snap }}
                  </v-tab>
                  <v-tab>
                    {{ ratingTexts[homework.homeworkType].rate4.snap }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-card
                        flat
                    >
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <div style="font-size: 20px">{{ ratingTexts[homework.homeworkType].rate1.text }}</div>
                          </v-col>
                          <v-spacer/>
                          <v-col>
                            <v-btn-toggle v-model="rates.rate1">
                              <v-btn v-for="i in 9" :key="i" small fab>{{ i }}</v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea
                            outlined
                            height="130px"
                            class="mb-n8"
                            v-model="reviews.rate1"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                        flat
                    >
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <div style="font-size: 16px">{{ ratingTexts[homework.homeworkType].rate2.text }}</div>
                          </v-col>
                          <v-spacer/>
                          <v-col>
                            <v-btn-toggle v-model="rates.rate2">
                              <v-btn v-for="i in 9" :key="i" small fab>{{ i }}</v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea outlined height="130px" class="mb-n8" v-model="reviews.rate2" flat></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                        flat
                    >
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <div style="font-size: 20px">{{ ratingTexts[homework.homeworkType].rate3.text }}</div>
                          </v-col>
                          <v-spacer/>
                          <v-col>
                            <v-btn-toggle v-model="rates.rate3">
                              <v-btn v-for="i in 9" :key="i" small fab>{{ i }}</v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea outlined height="130px" class="mb-n8" v-model="reviews.rate3" flat></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card
                        flat
                    >
                      <v-card-title>
                        <v-row>
                          <v-col>
                            <div style="font-size: 12px">{{ ratingTexts[homework.homeworkType].rate4.text }}</div>
                          </v-col>
                          <v-spacer/>
                          <v-col>
                            <v-btn-toggle v-model="rates.rate4">
                              <v-btn v-for="i in 9" :key="i" small fab>{{ i }}</v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea outlined height="130px" class="mb-n8" v-model="reviews.rate4" flat></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="card-center">
          <v-col>
            <v-card
                :class="$vuetify.theme.dark ? 'card-translate-up grey darken-3' : 'card-translate-up grey lighten-3'"
                elevation="24">
              <v-card-title>{{ $i18n.t("Write A Review") }}</v-card-title>
              <v-card-text class="scroll-y-auto">
                <v-textarea solo height="220px" class="mb-n8" v-model="review" flat></v-textarea>
                <v-row>
                  <v-col cols="12" lg="3" class="d-flex justify-end" v-if="!review">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="onhold" v-bind="attrs" v-on="on" block class="info">
                          {{ $i18n.t("Have question?") }}
                        </v-btn>
                      </template>
                      <span>{{ $i18n.t("Abort reviewing and report this homework") }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="12" lg="4" class="d-flex justify-end">
                    <v-row>
                      <v-checkbox v-model="showHomework" label="Demonstrate this homework"/>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="3" class="d-flex justify-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :class="review === '' || finalRate===undefined ? 'error': 'success'"
                               @click="submitReview"
                               v-bind="attrs" v-on="on" block :disabled="review === '' || finalRate===undefined">
                          {{ $i18n.t("Submit") }}
                        </v-btn>
                      </template>
                      <span>{{
                          review === ''
                          || finalRate === undefined ?
                              $i18n.t('You should write a review and rate the work!') :
                              $i18n.t('submit your review!')
                        }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" lg="3" class="d-flex justify-end">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="discard" v-bind="attrs" v-on="on" block>{{ $i18n.t("Abort") }}</v-btn>
                      </template>
                      <span>{{ $i18n.t("Abort reviewing and discard the changes") }}</span>
                    </v-tooltip>
                  </v-col>
                  <!--                  <v-col>-->
                  <!--                    <span>Time Remained: 23:59</span>-->
                  <!--                  </v-col>-->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--    </v-row>-->
    <!--      </transition>-->
    <!--    </v-container>-->
  </v-container>
</template>

<script>
import anime from "animejs";
import exercise from "@/utils/exercise";
import User from "@/utils/User";

export default {
  name: "HomeworkReview",
  data() {
    return {
      tab: null,
      showBottom: true,
      hideQuestion: false,
      expandQuestion: false,
      showHomework: this.reviewing ? this.homework.showHomework : false,
      questionHeight: 500,
      offsetTop: 0,
      boughtCourses: [],
      rates: {
        rate1: this.reviewing ? this.homework.rate.first.score - 1 : undefined,
        rate2: this.reviewing ? this.homework.rate.second.score - 1 : undefined,
        rate3: this.reviewing ? this.homework.rate.third.score - 1 : undefined,
        rate4: this.reviewing ? this.homework.rate.forth.score - 1 : undefined,
      },
      reviews: {
        rate1: this.reviewing ? this.homework.rate.first.review : "",
        rate2: this.reviewing ? this.homework.rate.second.review : "",
        rate3: this.reviewing ? this.homework.rate.third.review : "",
        rate4: this.reviewing ? this.homework.rate.forth.review : "",
      },
      review: this.reviewing ? this.homework.review : "",
      finalRate: this.reviewing ? this.homework.rate.finalRate : undefined,
      webHeight: document.body.clientHeight,
      reviewing: false,
      ratingTexts: {
        WRITING_A: {
          rate1: {
            snap: "TA",
            text: "Task Achievement"
          },
          rate2: {
            snap: "CC",
            text: "Coherence & Cohesion"
          },
          rate3: {
            snap: "LR",
            text: "Lexical Resource"
          },
          rate4: {
            snap: "GRA",
            text: "Grammatical Range & Accuracy"
          },
        },
        WRITING_B: {
          rate1: {
            snap: "TR",
            text: "Task Response"
          },
          rate2: {
            snap: "CC",
            text: "Coherence & Cohesion"
          },
          rate3: {
            snap: "LR",
            text: "Lexical Resource"
          },
          rate4: {
            snap: "GRA",
            text: "Grammatical Range & Accuracy"
          }
        },
        SPEAKING_A: {
          rate1: {
            snap: "FC",
            text: "Fluency & Coherence"
          },
          rate2: {
            snap: "LR",
            text: "Lexical Resource"
          },
          rate3: {
            snap: "GRA",
            text: "Grammatical range and accuracy"
          },
          rate4: {
            snap: "P",
            text: "Pronunciation"
          }
        }
      }
    }
  },
  props: ["homework"],
  created() {
    if (this.homework.rate) {
      this.reviewing = true;
      this.rates.rate1 = this.homework.rate.first.score - 1;
      this.rates.rate2 = this.homework.rate.second.score - 1;
      this.rates.rate3 = this.homework.rate.third.score - 1;
      this.rates.rate4 = this.homework.rate.forth.score - 1;
      this.reviews.rate1 = this.homework.rate.first.review;
      this.reviews.rate2 = this.homework.rate.second.review;
      this.reviews.rate3 = this.homework.rate.third.review;
      this.reviews.rate4 = this.homework.rate.forth.review;
      this.review = this.homework.review;
      this.finalRate = this.homework.rate.finalRate;
    }
    User.getUserAccountDetails(this.homework.userId, res => {
      this.boughtCourses = res.courses
    })
  },
  methods: {
    getCourseChip(course) {
      let courseId = {
        "627aff0c6222ae3ecb845999": "W",
        "627aff3a6222ae3ecb84599a": "R",
        "627affb86222ae3ecb84599b": "L",
        "627afedb6222ae3ecb845998": "S"
      };
      return courseId[course]
    },
    onScroll() {
      this.offsetTop = window.pageYOffset
    },
    getQuestionHeight() {
      if (this.expandQuestion) {
        return window.innerHeight
      } else {
        return 500
      }
    },
    submitReview() {
      if (this.review === '' || this.rate === 0) {
        return
      }
      let result = {
        review: this.review,
        rate: JSON.stringify({
          first: {
            score: this.rates.rate1 + 1,
            review: this.reviews.rate1
          },
          second: {
            score: this.rates.rate2 + 1,
            review: this.reviews.rate2
          },
          third: {
            score: this.rates.rate3 + 1,
            review: this.reviews.rate3
          },
          forth: {
            score: this.rates.rate4 + 1,
            review: this.reviews.rate4
          },
          finalRate: this.finalRate
        })
      }
      this.$store.state.loading = true;
      if (this.reviewing) {
        exercise.updateHomeworkReview(this.homework._id, result, () => {
          this.$store.state.marking = false
          this.$store.state.loading = false
          if (this.showHomework) {
            exercise.showHomework(this.homework._id)
          } else {
            exercise.unshowHomework(this.homework._id)
          }
        })
      } else {
        exercise.submitHomeworkReview(this.homework._id, result, () => {
          this.$store.state.marking = false
          this.$store.state.loading = false
          if (this.showHomework) {
            exercise.showHomework(this.homework._id)
          }
        })
      }
    },
    discard() {
      if (this.reviewing) {
        this.$store.state.marking = false
        return;
      }
      exercise.discardHomeworkReview(this.homework._id, () => {
        this.$store.state.marking = false
      })
    },
    onhold() {
      exercise.onholdHomeworkReview(this.homework._id, () => {
        this.$store.state.marking = false
      })
    },
    hideBottom() {
      this.showBottom = !this.showBottom
      this.playAnimation()
    },
    getText(str) {
      try {
        return atob(str)
      } catch (e) {
        return str
      }
    },
    playAnimation() {
      try {
        anime({
          targets: '.card-translate-up',
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(75),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-translate-up h1', '.card-translate-up h2', '.card-translate-up p', '.card-translate-up span:not(.v-btn__content)'],
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(25),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-fade-appear'],
          scaleY: [1.1, 1],
          duration: 325,
          easing: "easeOutQuint"
        })
      } catch (e) {
        console.warn("Animation", "error when animating animation", e)
      }
    }
  },
  watch: {
    "rates": {
      deep: true,
      handler: function () {
        let number = Math.floor((this.rates.rate1 + this.rates.rate2 + this.rates.rate3 + this.rates.rate4 + 4) / 2) / 2;
        if (isNaN(number)) {
          return;
        }
        this.finalRate = number
      }
    },
    "expandQuestion": {
      deep: false,
      handler: function () {
        if (this.expandQuestion) {
          this.questionHeight = this.getQuestionHeight() - 200
        } else {
          this.questionHeight = this.getQuestionHeight()
        }
      }
    }
  }
}
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
}

.trend-container1 {
  width: 100%;
  border: none;
  z-index: 0;
}

.bottom-container {
  position: absolute;
  z-index: 1;
}

.card-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  z-index: 10;
}
</style>
