<template>
  <div></div>
  <!-- v-card color="red" dark class="rounded-0">
    <v-card-title class="headline">
      新春快乐!
    </v-card-title>
    <v-card-subtitle>祝大家2021春节快乐</v-card-subtitle>
    <v-card-actions>
      <v-btn text disabled="disabled">
        暂时没有可用的活动
      </v-btn>
    </v-card-actions>
  </v-card !-->
</template>

<script>
export default {
  name: "NoticeCard"
}
</script>

<style scoped>

</style>