<template>
  <v-container>
    <v-container v-if="viewExercise == null || !nextLevel">
      <v-row justify-lg="end" justify-md="end">
        <ExerciseForm @refresh="refreshExercise(options.groupBy, options.page, options.itemsPerPage, options.groupDesc)"
                      :item="null" :course-list="courseList"/>
      </v-row>
      <v-row>
        <v-col>
          <h2>{{$i18n.t("Exercises List")}}</h2>
        </v-col>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="exerciseList"
              :server-items-length="serverItemsLength"
              :loading="loading"
              :options.sync="options"
              :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      text
                  >
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                        @click="edit(item)"
                        icon
                    >
                      <v-icon style="color: #2196f3">
                        mdi-folder-open-outline
                      </v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <ExerciseForm
                        @refresh="refreshExercise(options.groupBy, options.page, options.itemsPerPage, options.groupDesc)"
                        :item="item" :key="item._id" :course-list="courseList"/>
                  </v-list-item>
                  <v-list-item>
                    <DeleteBtn @confirm="del(item)" :icon="true"/>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row justify="center">
        <v-col cols="12">
          <QuestionView :item="viewExercise" @back="back"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ExerciseForm from "@/components/ExerciseForm";
import QuestionView from "@/components/QuestionView";
import exercise from "@/utils/exercise";
import course from "@/utils/Course";
import DeleteBtn from "@/components/DeleteBtn";

export default {
  name: "Questions",
  components: {DeleteBtn, ExerciseForm, QuestionView},
  data() {
    return {
      loading: true,
      nextLevel: false,
      search: '',
      viewExercise: null,
      createNewExercise: false,
      serverItemsLength: 0,
      courseList: [],
      options: {
        groupBy: [],
        page: 1,
        itemsPerPage: 50,
        groupDesc: []
      },
      exerciseList: [],
      headers: [
        {text: 'ID', value: '_id', align: 'start', sortable: false},
        {text: 'Name', value: 'name', align: 'start', sortable: false},
        {text: 'Course', value: 'course.name', align: 'start', sortable: false},
        {text: 'Category', value: 'group', align: 'start', sortable: false},
        {text: 'Question Quantity', value: 'questionNumber', align: 'start', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false, filterable: false},
      ]
    }
  },
  methods: {
    edit(item) {
      this.viewExercise = item
      this.nextLevel = true
    },
    del(item) {
      exercise.removeExercise(item.course._id, item._id, () => {
        this.refreshExercise(this.options.groupBy, this.options.page, this.options.itemsPerPage, this.options.groupDesc)
      })
    },
    back() {
      if (this.nextLevel) {
        if (this.createNewQuestion) {
          this.createNewQuestion = false
        }
        this.nextLevel = false
      }
    },
    refreshExercise(group, page, itemsPerPage, groupDesc) {
      exercise.getExerciseList(group, page, itemsPerPage, groupDesc, (res) => {
        this.exerciseList = res.entities
        this.serverItemsLength = res.count
        this.loading = false
      })
    },
  },
  created() {
    this.refreshExercise(this.options.groupBy, this.options.page, this.options.itemsPerPage, this.options.groupDesc)
    course.getAllCourse(100000, 0, res => {
      this.courseList = res.entities
      this.courseList.map((item) => {
        try {
          item.desText = atob(item.desText)
        } catch (e) {
          console.warn("Warning: description cannot decode by Base64")
        }
      })
    })
  },
  mounted() {
    this.$store.state.loading = false;
  },
  watch: {
    options: {
      handler() {
        this.refreshExercise(this.options.groupBy, this.options.page, this.options.itemsPerPage, this.options.groupDesc)
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
