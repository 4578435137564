<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="3" v-for="item in (archivedVisible ? courseList: activatedList)" :key="item._id">
        <CoursePreview :courseEntity="item" @active="activeCourse" @archive="archiveCourse"/>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="activatedList.length === 0 && !archivedVisible" class="pt-16">
      <v-img src="../../assets/no-course.svg" max-height="200px" max-width="200px"></v-img>
      <v-col cols="12">
        <div class="text-center pt-6 text-h4 text--darken-1">{{$i18n.t("No Active Course")}}</div>
        <p class="text-center pt-3">{{$i18n.t("You can add a course or check archived courses")}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Course from "@/utils/Course";
import CoursePreview from "@/components/course/CoursePreview";

export default {
  name: "CoursePreviewGrid",
  components: {CoursePreview},
  props: {
    "archivedVisible": {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      activatedList: [],
      courseList: [],
    }
  },
  created() {
    this.$store.state.loading = true
    Course.getAllCourse(10000,1, (list) => {
      this.courseList = list.entities
      for (const i in this.courseList){
        try {
          this.courseList[i].desText = atob(this.courseList[i].desText)
        }catch (e) {
          console.warn("Warning: description cannot decode by Base64")
        }
        if (!this.courseList[i].archived){
          this.activatedList.push(this.courseList[i])
        }
      }
      this.$store.state.loading = false
    })
  },
  methods: {
    archiveCourse(item){
      item.archived = true
      this.activatedList.splice(this.activatedList.indexOf(item), 1)
    },
    activeCourse(item){
      item.archived = false
      this.activatedList.push(item)
    }
  }
}
</script>

<style scoped>

</style>
