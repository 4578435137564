<template>
  <div>
    <v-container v-if="!$store.state.user.status" class="pt-16">
      <v-row justify="center">
        <v-img src="../assets/no-access.svg" max-width="200px" max-height="200px"/>
        <v-col cols="12">
          <div class="text-center pt-6 text-h4 text--darken-1">{{$i18n.t("Please Login First!")}}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-spacer/>
        <v-checkbox :label="$i18n.t('Show Archived Courses')" v-model="archivedVisible"></v-checkbox>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
              color="primary"
              @click="$router.push({path: '/course/add'})"
          >
            {{$i18n.t("Add Course")}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <CoursePreviewGrid :archivedVisible="archivedVisible"/>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import CoursePreviewGrid from "@/components/course/CoursePreviewGrid";
import store from "@/store";

export default {
  name: "Course",
  components: {CoursePreviewGrid},
  data() {
    return {
      dialogVisible: false,
      archivedVisible: false,
      time: null,
    }
  },
  created() {
  },
  methods: {
    closeAvatarDialog() {
      this.dialogVisible = false
    }
  },
  mounted() {
    store.state.loading = false;
  },
}
</script>

<style scoped>

</style>
