import api from "@/store/api";
import axios from "axios";
import snackBar from "@/utils/SnackBar";

let Vimeo = require('vimeo').Vimeo;
let client = new Vimeo(api.VIMEO_CLIENT_ID, api.VIMEO_CLIENT_SECRET, api.VIMEO_PRIVATE_ACCESS_TOKEN);

function getVideoLength(videoUri, func) {
    client.request(videoUri + '?fields=duration', function (error, body) {
        func(body.duration)
    })
}

function uploadVideo(videoFile, videoName = "", videoDisc = "", finishFunc, processingFunc, errorFunc) {
    client.upload(
        videoFile,
        {
            'name': videoName,
            'description': videoDisc,
            'folder_uri': '/projects/8461373',
        },
        function (uri) {
            finishFunc(uri)
        },
        function (bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            processingFunc(percentage, bytesUploaded, bytesTotal)
        },
        function (error) {
            errorFunc(error)
        }
    )
}

function checkTranscodeState(uri, func){
    client.request(uri + '?fields=transcode.status', function (error, body) {
        if (body.transcode.status === 'complete') {
            func(true)
        } else if (body.transcode.status === 'in_progress') {
            func(false)
        } else {
            func(false, error)
        }
    })
}

function createThumbNail(videoId, second, func) {
    axios.post(
        api.VIMEO_BASE_URL + videoId + '/pictures',
        {"time": second, "active": true},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + api.VIMEO_PRIVATE_ACCESS_TOKEN, // headers塞token
                'Accept': 'application/vnd.vimeo.*+json;version=3.4'
            }
        }
    ).then((res) => {
        func(res)
    }).catch((error) => {
        snackBar.Launch(error.data)
    })
}

function getThumbNails(videoId, func) {
    axios.get(
        api.VIMEO_BASE_URL + videoId + "/pictures",
        {
            headers: {
                'Authorization': 'bearer ' + api.VIMEO_PRIVATE_ACCESS_TOKEN, // headers塞token
                'Accept': 'application/vnd.vimeo.*+json;version=3.4'
            }
        }
    ).then((res) => {
        func(res)
    }).catch((error) => {
        snackBar.Launch(error.data)
    })
}

export default {uploadVideo, getThumbNails, createThumbNail, checkTranscodeState, getVideoLength}
