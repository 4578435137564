import axios from "@/utils/axios";
import api from "@/store/api";
import snackBar from "@/utils/SnackBar";

function addNewCourse(data, func){
    data.desText = btoa(data.desText)
    axios.post(
        api.COURSE_ADD,
        data
    ).then(()=>{
        func()
    }).catch((error) => {
        snackBar.Launch(error.data.message)
    })
}

function getCourseByInstructorId(id, func){
    axios.get(
        api.GET_COURSE_LIST_BY_INSTRUCTOR+'?instructorId='+id
    ).then((res) => {
        func(res.data.data)
    }).catch((error)=>{
        snackBar.Launch(error.data.message)
    })
}

function archiveCourse(id, func){
    axios.post(
        api.ARCHIVE_COURSE + "?course_id=" + id
    ).then(res => {
        func(res.data.data)
    }).catch(error => {
        if (error.data !== null){
            snackBar.Launch(error.data)
        }
    })
}

function activeCourse(id, func){
    axios.post(
        api.ACTIVE_COURSE + "?course_id=" + id
    ).then(res => {
        func(res.data.data)
    }).catch(error => {
        if (error.data !== null){
            snackBar.Launch(error.data)
        }
    })
}

function getAllCourse(limit, page, func){
    axios.get(
        api.GET_ALL_COURSE+"?limit="+limit+"&page="+page
    ).then(res => {
        func(res.data.data)
    })
}

function getCourse(id, func){
    axios.get(
        api.COURSE_GET+'?course_id='+id
    ).then((res)=>{
        func(res.data.data)
    })
}

function updateCourse(course, func){
    course.desText = btoa(course.desText)
    axios.post(
        api.UPDATE_COURSE,
        course
    ).then(() => {
        func()
    })
}

export default {addNewCourse, getCourseByInstructorId, archiveCourse, activeCourse, getAllCourse, getCourse, updateCourse}
