<template>
  <v-container>
    <v-container v-if="createNewQuestion || editQuestion !== null">
      <v-row justify="center">
        <v-col cols="9">
          <v-btn @click="backFromForm" text large>
            <v-icon>mdi-arrow-left</v-icon>
            {{$i18n.t("Back")}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="9">
          <QuestionForm :exercise="item" @refresh="refresh" @back="backFromForm" :item="editQuestion"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12" lg="2">
          <v-btn @click="back" text large>
            <v-icon>mdi-arrow-left</v-icon>
            {{$i18n.t("Back")}}
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col cols="12" lg="2">
          <v-btn @click="addNewQuestion" text large>
            <v-icon>mdi-plus</v-icon>
            {{$i18n.t("Add new question")}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <h1 class="ml-16">{{item.name}} - ID: {{item._id}}</h1>
      </v-row>
      <v-row justify="center" v-if="questionList.length > 0">
        <v-card v-for="(each, index) in questionList" :key="index" min-width="400">
          <v-card-title>
            <v-row>
              <v-col>
                <v-btn class="primary" @click="editAQuestion(each)">
                  <v-icon>mdi-pencil</v-icon>
                  {{$i18n.t("Edit")}}
                </v-btn>
              </v-col>
              <v-col>
                <DeleteBtn :icon="false" @confirm="deleteQuestion(each._id)"/>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div v-if="each !== null" v-html="decode(each.text)"></div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row justify="center" v-if="questionList.length === 0" class="pt-16">
        <v-img src="../assets/no-course.svg" max-height="200px" max-width="200px"></v-img>
        <v-col cols="12">
          <div class="text-center pt-6 text-h4 text--darken-1">{{$i18n.t("No Question")}}</div>
          <p class="text-center pt-3">{{$i18n.t("You can add a course through the button at top right")}}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import QuestionForm from "@/components/QuestionForm";
import exercise from "@/utils/exercise";
import DeleteBtn from "@/components/DeleteBtn";

export default {
  name: "QuestionView",
  components: {DeleteBtn, QuestionForm},
  data() {
    return {
      createNewQuestion: false,
      editQuestion: null,
      questionList: []
    }
  },
  created() {
    this.questionList = this.item.questions
  },
  props: {
    item: {
      type: Object
    }
  },
  methods:{
    back(){
      this.$emit('back')
    },
    backFromForm(){
      this.createNewQuestion = false
      this.editQuestion = null
    },
    editAQuestion(item){
      this.createNewQuestion = false
      this.editQuestion = item
    },
    addNewQuestion(){
      this.editQuestion = null
      this.createNewQuestion = true
    },
    refreshCache(){
      exercise.getExerciseQuestions(this.item._id, res => {
        this.questionList = res
      })
    },
    refresh(questionId) {
      this.createNewQuestion = false
      this.editQuestion = null
      if (questionId === null){
        this.refreshCache()
        return;
      }
      exercise.getQuestionDetails(questionId, (res) => {
        this.questionList.push(res)
      })
    },
    deleteQuestion(questionId){
      exercise.removeQuestion(this.item._id, questionId, () => {
        this.refreshCache()
      })
    },
    decode(base64String){
      return atob(base64String)
    }
  }
}
</script>

<style scoped>

</style>
