<template>
  <v-container class="ma-auto">
    <v-row justify="center" class="ma-auto">
      <v-spacer/>
      <v-col>
        <v-img src="../assets/logo_full.png" max-width="600px"/>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row justify="center" class="ma-auto" v-if="choice === -1">
      <v-col cols="12" lg="3" class="mr-lg-5">
        <v-hover v-slot:default="{ hover }">
          <v-card height="400" class="pt-16" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" :elevation="hover ? 13 : 3">
            <v-row justify="center" class="mt-5">
              <v-btn
                  @click="choice = 1"
                  text
                  class="ma-16 no-background-hover"
                  :ripple="false"
              >
                <div>
                  <v-img
                      src="../assets/teacher-svg.svg"
                      max-width="150"
                      style="color: grey"
                      class="mt-10 mb-8 ml-5"
                      contain
                  >
                  </v-img>
                  <strong>{{$i18n.t("Teacher/Admin Login")}}</strong>
                </div>
              </v-btn>
            </v-row>
          </v-card>
        </v-hover>

      </v-col>
      <v-divider vertical/>
      <v-col cols="12" lg="3" class="ml-lg-5">
        <v-hover v-slot:default="{ hover }">
          <v-card height="400" class="pt-16" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" :elevation="hover ? 13 : 3">
            <v-row justify="center" class="mt-5">
              <v-btn
                  @click="choice = 2"
                  text
                  class="ma-16 no-background-hover"
                  :ripple="false"
              >
                <div>
                  <v-img
                      :src="require('../assets/student-svg.svg')"
                      max-width="200"
                      style="color: grey"
                      class="mt-10 mb-8"
                      contain
                  >
                  </v-img>
                  <strong>{{$i18n.t("Student Login")}}</strong>
                </div>
              </v-btn>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-auto" v-else>
      <v-col cols="12" lg="5">
        <LoginForm/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import store from "@/store";
export default {
  name: "Portal",
  components: {LoginForm},
  data() {
    return {
      choice: -1,
    }
  },
  mounted() {
    store.state.loading = false;
  },
}
</script>

<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
