<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="550px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            v-if="item == null"
        >
          <v-icon>mdi-plus</v-icon>
          {{$i18n.t("Add An Exercise")}}
        </v-btn>
        <v-btn
            color="warning"
            v-bind="attrs"
            v-on="on"
            v-else
            icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{$i18n.t("Exercise Editor")}}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Exercise Name" v-model="dataForm.name"></v-text-field>
              </v-col>
              <v-col cols="12" class="mb-n5">
                <div>{{$i18n.t("Exercise Category")}}</div>
              </v-col>
              <v-col cols="12">
                <v-btn-toggle mandatory v-model="dataForm.group">
                  <v-list>
                    <v-btn
                        v-for="(item, index) in questionSections"
                        :key="index"
                        :value="item"
                        class="mr-2"
                    >
                      <span>{{ item }}</span>
                    </v-btn>
                  </v-list>
                </v-btn-toggle>
              </v-col>
              <v-col cols="7">
                <v-radio-group v-model="dataForm.academic" row>
                  <v-radio label="Academic" :value="true"/>
                  <v-radio label="General" :value="false"/>
                </v-radio-group>
              </v-col>
              <v-col cols="5">
                <v-text-field label="Question Quantity" v-model="dataForm.questionNumber"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                    label="Select Course"
                    :items="courseList"
                    v-model="selectedCourse"
                    item-text="name"
                    item-value="_id"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col>
                <v-btn block text @click="dialog = false">{{$i18n.t("Cancel")}}</v-btn>
              </v-col>
              <v-col>
                <v-btn block class="success" @click="submit">{{$i18n.t("Save")}}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import exercise from "@/utils/exercise";

export default {
  name: "ExerciseForm",
  data() {
    return {
      dialog: false,
      questionSections: ["Listening", "Reading", "Writing", "Speaking"],
      selectedCourse: null,
      dataForm: {
        name: '',
        group: null,
        academic: true,
        courseId: '',
        questionNumber: 0,
      }
    }
  },
  props: ["item", "courseList"],
  created() {
    if (this.item !== null) {
      this.selectedCourse = this.item.course._id
      this.dataForm = {
        _id: this.item._id,
        name: this.item.name,
        group: this.item.group,
        academic: this.item.academic,
        questionNumber: this.item.questionNumber,
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.dataForm.name = ''
      this.dataForm.group = ''
      this.dataForm.courseId = ''
      this.selectedCourse = null
    },
    submit() {
      this.dataForm.courseId = this.selectedCourse
      if (this.dataForm._id === undefined) {
        exercise.addExercise(this.dataForm, () => {
          this.close()
          this.$emit('refresh')
        })
      } else {
        exercise.updateExercise(this.dataForm, () => {
          this.close()
          this.$emit('refresh')
        })
      }

    },
  }
}
</script>

<style scoped>

</style>
