<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-col cols="11">
          {{ item == null ? $i18n.t('New Daily Post') : $i18n.t('Edit Daily Post') }}
        </v-col>
        <v-col cols="1">
          <v-btn block large color="green" @click="save">{{$i18n.t("Save")}}</v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="postData.title" :label="$i18n.t('Title')"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="postData.subtitle" :label="$i18n.t('Subtitle')"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RichEditor @setText="setContent" :editor-text="postData.content" :editorHeight="1000"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="metas.length > 0" cols="12">
            <v-row>
              <div class="text-h6">{{$i18n.t("Meta Data")}}</div>
            </v-row>
            <v-row v-for="(item, i) in metas" :key="i">
              <v-col>
                <v-text-field v-model="metas.at(i).key"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="metas.at(i).value"></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn text icon @click="removeMetaKeys(i)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-btn @click="addMetaKeys" x-large>
              <v-icon>mdi-plus</v-icon>
              {{$i18n.t("ADD METADATA")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RichEditor from "@/components/RichEditor";
import store from "@/store";
import DailyPost from "@/utils/DailyPost";

export default {
  name: "DailyPostForm",
  components: {RichEditor},
  data() {
    return {
      tinymceFlag: 1,
      postData: {
        content: "",
        postBy: store.state.user._id,
        subtitle: "",
        title: "",
        metaData: {
        }
      },
      metas:[]
    }
  },
  methods: {
    save() {
      this.postData.metaData = {}
      for (const item in this.metas){
        this.postData.metaData[this.metas.at(parseInt(item)).key] = this.metas.at(parseInt(item)).value
      }
      if (this.item === null) {
        DailyPost.addDailyPost(this.postData, () => {
          this.$emit('success')
        })
      } else {
        DailyPost.updateDailyPost(this.postData, () => {
          this.$emit('success')
        })
      }
    },
    setContent(text) {
      this.postData.content = text
    },
    getMetaKeys(){
      return Object.keys(this.postData.metaData)
    },
    addMetaKeys(){
      this.metas.push(
          {
            key: "",
            value: ""
          }
      )
    },
    removeMetaKeys(i){
      this.metas.splice(i, 1)
    },
    loadMeta(obj){
      for (const item in obj){
        this.metas.push(
            {
              key: item,
              value: obj[item]
            }
        )
      }
    }
  },
  created() {
    if (this.item != null) {
      this.postData = this.item
      this.postData.content = atob(this.postData.content)
      if ("metaData" in this.item && this.item.metaData !== null){
        this.loadMeta(this.item.metaData)
      }
    }
  },
  props: {
    "item": {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
