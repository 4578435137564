<template>
  <v-dialog v-model="$store.state.viewingUserDetails" max-width="500px" onclose="$store.state.viewingUserDetails = null">
    <v-card class="pb-10">
      <v-card-title class="text-center pb-10">
        <v-row>
          <v-col>
            <v-btn text :ripple="false" id="no-background-hover" @click="$store.state.viewingUserDetails = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <div class="text-h4">{{$i18n.t("User Inspector")}}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-img
                :src="item.photoUrl === 'null' ? require('../assets/profile.png') : item.photoUrl"
                lazy-src="../assets/profile.png"
                :alt="item.firstName +' '+ item.lastName"
                style="border-radius: 50%"
                max-height="200px"
                max-width="200px"
                class="mx-auto"
            />
          </v-col>
          <v-col>
            <div class="text-caption text-center">UID: {{ item._id }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-h5">{{$i18n.t("Personal Information")}}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div>First name:</div>
          </v-col>
          <v-col cols="3">
            <strong>{{ item.firstName }}</strong>
          </v-col>
          <v-col cols="3">
            <div>{{$i18n.t("Last name")}}:</div>
          </v-col>
          <v-col cols="3">
            <strong> {{ item.lastName }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div>{{$i18n.t("Gender")}}:</div>
          </v-col>
          <v-col cols="3">
            <strong>{{ item.gender }}</strong>
          </v-col>
          <v-col cols="3">
            <div>{{$i18n.t("Region")}}:</div>
          </v-col>
          <v-col cols="3">
            <strong>{{ item.country }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div>{{$i18n.t("Join Date")}}:</div>
          </v-col>
          <v-col cols="3">
            <strong>{{ new Date(item.joinDate).toLocaleString() }}</strong>
          </v-col>
          <v-col cols="3">
            <div>{{$i18n.t("Target Score")}}:</div>
          </v-col>
          <v-col cols="3">
            <strong>{{ item.targetScore }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-h5">{{$i18n.t("Contact Information")}}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div>{{$i18n.t("Phone")}}:</div>
          </v-col>
          <v-col cols="6">
            <strong>{{ item.phoneNumber }}</strong>
          </v-col>
          <v-col cols="3">
            <div v-if="item.phoneNumber">
              <v-icon style="color: green">mdi-check</v-icon>
              {{$i18n.t("Verified")}}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div>{{$i18n.t("Email")}}:</div>
          </v-col>
          <v-col cols="6">
            <strong>{{ item.email }}</strong>
          </v-col>
          <v-col cols="3">
            <div v-if="item.emailValidated">
              <v-icon style="color: green">mdi-check</v-icon>
              {{$i18n.t("Verified")}}
            </div>
            <div v-else>
              <v-icon style="color: red">mdi-close</v-icon>
              <small> {{$i18n.t("Unverified")}}</small>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CheckUserDetails",
  data() {
    return {
      item: this.$store.state.viewingUserDetails
    }
  }
}
</script>

<style scoped>

</style>
