import Vue from 'vue'
import Vuex from 'vuex'
import i18n from "@/i18n";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        payment: [],
        auth: [],
        page: '',
        nav: {
          mini: false
        },
        marking: false,
        viewingUserDetails: null,
        viewingTokenSettings: null,
        viewingAccountSettings: null,
        viewingUserStatistic: null,
        notifications: [],
        style: {
            select: {text: i18n.t('Light'), mode: true},
            style: [
                {text: i18n.t('Dark'), mode: true},
                {text: i18n.t('Light'), mode: false},
                {text: i18n.t('Follow system settings'), mode: 'auto'}
            ],
        },
        lang: {
            select: {text: "中文(简体)", locale: "zh_CN"},
            lang: [
                {text: "中文(简体)", locale: "zh_CN"},
                {text: "English(US)", locale: "en_US"}
            ],
        },
        LoginMsg: {
            LoginSuccess: 'Login success',
            LoginError: 'Wrong user name or password',
            ConnErr: 'Login failed',
            Invalid: 'Login status is invalid, please login again',
        },
        SnackBar: {
            timeout: 3000,
            text: '',
            snackbar: false,
        },
        askingDialog: {
            enable: false,
            text: '',
            func: Function
        },
        user: {
            isStudent: true,
            status: false,
            token: {
                access: '',
                refresh: ''
            },
            _id: '',
            firstName: '',
            lastName: '',
            gender: '',
            country: '',
            examType: 0,
            emailValidated: false,
            targetScore: 0.0,
            photoUrl: '',
            bodyUrl: '',
            email: '',
            phoneNumber: '',
            joinDate: 0,
            description: '',
            rate: 0.0,
            like: 0,
            title: ''
        },
        userStates: {},
        NavBar: {
            drawer: false,
            group: null,
        },
        LoginForm: {
            username: '',
            password: '',
        },
        RegForm: {
            username: '',
            password: '',
            email: '',
        },
    },
    mutations: {
        setAccessToken(state, value) {
            state.user.token.access = value
        },
        setRefreshToken(state, value) {
            state.user.token.refresh = value
        },
        setUserId(state, value) {
            state.user._id = value
        },
        setUserName(state, value) {
            state.user.firstName = value
        },
        setServerList(state, value) {
            state.userList = value
        },
        setMaxServer(state, value) {
            state.user.maxServer = value
        },
        setPaymentList(state, value) {
            state.payment = value
        }
    },
    actions: {
        setMaxServer(context, value) {
            context.commit('setMaxServer', value)
        },
        setUserId(context, value) {
            context.commit('setUserId', value)
        }
    },
    modules: {},
    getters: {}
})
