import store from "@/store";
import axios from "@/utils/axios";
import api from "@/store/api";
import snackBar from "@/utils/SnackBar";
import SnackBar from "@/utils/SnackBar";

function setUserDetails(data) {
  store.state.user._id = data._id
  store.state.user.firstName = (data.firstName === null || data.firstName === "null") ? "" : data.firstName
  store.state.user.lastName = (data.lastName === null || data.lastName === "null") ? "" : data.lastName
  store.state.user.gender = (data.gender === null || data.gender === "null") ? "" : data.gender
  store.state.user.country = (data.country === null || data.country === "null") ? "" : data.country
  store.state.user.examType = data.examType
  store.state.user.emailValidated = data.emailValidated
  store.state.user.targetScore = data.targetScore
  store.state.user.photoUrl = data.photoUrl
  store.state.user.bodyUrl = data.bodyUrl
  store.state.user.email = (data.email === null || data.email === "null") ? "" : data.email
  store.state.user.phoneNumber = (data.phoneNumber === null || data.phoneNumber === "null") ? "" : data.phoneNumber
  store.state.user.joinDate = data.joinDate
  store.state.user.description = (data.description === null || data.description === "null") ? "" : data.description
  store.state.user.rate = data.rate
  store.state.user.like = data.like
  store.state.user.title = (data.title === null || data.title === "null") ? "" : data.title
}

function getUserDetails() {
  axios.get(
    api.GET_USER,
    {}
  ).then((result) => {
      let data = JSON.parse(JSON.parse(JSON.stringify(result.data.data)))
      setUserDetails(data)
      store.state.user.status = true
    }
  ).catch(() => {
  })
}

function getUserEnhanceInfo(userId, func) {
  axios.get(
    api.GET_USER_INFO + '?user_id=' + userId
  ).then(res => {
    func(JSON.parse(res.data.data))
  })
}

function updateUserDetails(data) {
  axios.post(
    api.UPDATE_USER,
    data
  ).then(() => {
  }).catch(error => {
    snackBar.Launch(this.$i18n.t(store.state.LoginMsg.ConnErr) + ':' + error.message)
  })
}

function updatePassword(password) {
  axios.post(
    api.BASE_URL + api.UPDATE_PASSWORD,
    {password: password},
  ).catch(error => {
    snackBar.Launch(this.$i18n.t(store.state.LoginMsg.ConnErr) + ':' + error)
  })
}

async function getUserListAdmin(that, feature, content, compare) {
  if (that.options.page === undefined || that.options.itemsPerPage === undefined) {
    return;
  }
  that.loading = true
  that.userList = []
  let url = api.USER_ADMIN_LIST + '?page=' + that.options.page + '&size=' + that.options.itemsPerPage
  if (feature !== undefined && feature !== '') {
    url += '&feature=' + feature
    if (content !== undefined && content !== '') {
      url += '&content=' + content
    }
    if (compare && compare !== '') {
      url += '&compare=' + compare
    }
  }
  if (that.options.sortBy[0] !== '') {
    url += '&sortBy=' + that.options.sortBy[0] + '&ascend=' + !that.options.sortDesc[0]
  }
  axios.get(
    url
  ).then(async res => {
    let items = res.data.data
    if (items.userDetails.length === 0) {
      that.userList = []
      that.totalItems = 0
      return
    }
    // console.log(items.userDetails)
    that.userList = items.userDetails
    that.totalItems = items.count
  }).catch(error => {
    snackBar.Launch(this.$i18n.t(store.state.LoginMsg.ConnErr) + ':' + error)
  }).finally(() => {
    that.loading = false
  })
}

async function getUserTokenNumber(userId, func) {
  await axios.get(
    api.BASE_URL + api.USER_TOKEN_NUMBER + "?user_id=" + userId
  ).then((res) => {
    func(res.data.data)
  })
}

function updateUserTokenNumber(userId, number, func) {
  axios.post(
    api.BASE_URL + api.UPDATE_USER_TOKEN_NUMBER + "?user_id=" + userId + "&num=" + number
  ).then(() => {
    func()
  }).catch(error => {
    snackBar.Launch(error)
  })
}

function banUser(userId, block, func) {
  axios.post(
    api.BASE_URL + api.USER_BLOCK + "?user_id=" + userId + "&block=" + block
  ).then((res) => {
    func(res)
  })
}

async function getUserAccountDetails(userId, func) {
  await axios.get(
    api.BASE_URL + api.GET_USER_ACCOUNT_STATE + "?user_id=" + userId
  ).then((res) => {
    func(res.data)
  })
}

function getUserState(func) {
  axios.get(
    api.USER_GET_STATES
  ).then(res => {
    func(res.data.data)
  }).catch(() => {
  })
}

async function getUserStateAdmin(userId, func) {
  await axios.get(
    api.USER_GET_STATES_ADMIN + "?id=" + userId
  ).then(res => {
    func(res.data.data)
  }).catch(() => {
  })
}

function updateUserState(data, func) {
  axios.post(
    api.USER_UPDATE_STATES,
    data
  ).then(res => {
    func(res.data.data)
  })
}

function setUserAuth(userId, auths, func) {
  axios.post(
    api.BASE_URL + api.UPDATE_USER_AUTH + "?user_id=" + userId,
    auths
  ).then((res) => {
    func(res.data.data)
  })
}

function updateUserAuth(data, func) {
  axios.post(
    api.BASE_URL + api.UPDATE_USER_ACCOUNT,
    data
  ).then((res) => {
    func(res.data.data)
  }).catch((error) => {
    snackBar.Launch(error.message)
  })
}

function getUserPublicDetails(userId, func) {
  axios.get(
    api.GET_USER_PROFILE_DATA + '?user_id=' + userId
  ).then(res => {
    func(JSON.parse(res.data.data))
  })
}

function sendNotification(notification, func) {
  axios.post(
    api.USER_SEND_NOTIFICATION,
    notification
  ).then(()=> {
    func()
  })
}

function getUnreadNotification(func) {
  axios.get(
    api.USER_GET_UNREAD_NOTIFICATIONS
  ).then(res => {
    func(JSON.parse(res.data.data))
  }).catch(() => {
  })
}

function getAllNotification(senderId, receiverId, page, size, func) {
  let url = api.USER_GET_ALL_NOTIFICATIONS + '?senderId=' + senderId + '&receiverId=' + receiverId + '&page=' + page + '&size=' + size + '&systemNotification=false'
  axios.get(
    url
  ).then(res => {
    func(res.data.data)
  }).catch(() => {
  })
}

function readNotification(id, func) {
  axios.get(
    api.USER_READ_NOTIFICATION + '?id=' + id
  ).then(func())
}

function getNotificationSenderList(func) {
    axios.get(
        api.USER_GET_USER_NOTIFICATION_SENDER_LIST_V2
    ).then(res => {
        func(res.data.data)
    }).catch(() => {
    })

}

function generateUser(details, func) {
  axios.post(
    api.GENERATE_USER,
    details
  ).then(res => {
    func(res.data)
  }).catch(err => {
    if (err.response.status === 403) {
      SnackBar.Launch(err.response.data)
    }
  })
}

function updateUserCourse(userId, course, func) {
  axios.post(
    api.UPDATE_USER_COURSE + "?user_id=" + userId,
    course
  ).then(res => {
    func(res.data)
  }).catch(err => {
    if (err.response.status === 403) {
      SnackBar.Launch(err.response.data)
    }
  })
}

function exportCSVServer(that, feature, content, compare, func) {
  let url = api.EXPORT_USER_CSV + '?email=' + store.state.user.email
  if (feature !== undefined && feature !== '') {
    url += '&feature=' + feature
    if (content !== undefined && content !== '') {
      url += '&content=' + content
    }
    if (compare && compare !== '') {
      url += '&compare=' + compare
    }
  }
  axios.post(url).then(() => {
    func()
  })
  snackBar.Launch("Report generate request has been submitted.")
}

export default {
  data: setUserDetails,
  getUserDetails,
  getUserEnhanceInfo,
  updateUserDetails,
  updatePassword,
  getUserListAdmin,
  banUser,
  getUserAccountDetails,
  getUserState,
  setUserAuth,
  updateUserAuth,
  getUserTokenNumber,
  updateUserTokenNumber,
  updateUserState,
  getUserPublicDetails,
  getUnreadNotification,
  readNotification,
  getUserStateAdmin,
  sendNotification,
  generateUser,
  updateUserCourse,
  exportCSVServer,
  getAllNotification,
  getNotificationSenderList
}
