<template>
  <div>
    <v-container v-if="!$store.state.user.status" class="pt-16">
      <v-row justify="center">
        <v-img src="../assets/no-access.svg" max-width="200px" max-height="200px"/>
        <v-col cols="12">
          <div class="text-center pt-6 text-h4 text--darken-1">{{$i18n.t("Please Login First!")}}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-banner elevation="6" dark icon="mdi-account" v-else-if="!auth">{{$i18n.t("Access Denied")}}</v-banner>
    <template v-else>
      <v-container class="pt-8">
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>Generate User</v-card-title>
              <v-card-text>
                <v-form>
                  <v-row class="d-flex align-center">
                    <v-col cols="7">
                      <v-text-field label="username" v-model="randomUser.username"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <div>@trailuser.com</div>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon @click="randomUser.username = generateRandomString()">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center">
                    <v-col cols="10">
                      <v-text-field label="password" v-model="randomUser.password"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon @click="randomUser.password = generateRandomString()">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex align-center justify-center">
                    <v-btn class="ma-3" @click="generate">generate</v-btn>
                  </v-row>
                </v-form>
                <v-container v-if="lastGenerateUser">
                  <v-row>
                    Successfully generated user
                  </v-row>
                  <v-row>
                    ID: {{lastGenerateUser.id}}
                  </v-row>
                  <v-row>
                    Username: {{lastGenerateUser.username}}
                  </v-row>
                  <v-row>
                    Password: {{lastGenerateUser.password}}
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
<!--          <v-col cols="12" sm="12" lg="6">-->
<!--            <TeacherPopularityChart/>-->
<!--            <PendingCourseList class="mt-3"/>-->
<!--          </v-col>-->
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import token from "@/utils/token";
// import PendingCourseList from "@/components/PendingCourseList";
// import TeacherPopularityChart from "@/components/TeacherPopularityChart";
import store from "@/store";
import User from "@/utils/User";

export default {
  name: "Auth",
  components: {
    // PendingCourseList,
    // TeacherPopularityChart
  },
  data: () => ({
    auth: false,
    mock: {
      data: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      labels: ['8/1', '9/1', '10/1', '11/1', '12/1', '13/1', '14/1', '15/1', '16/1', '17/1', '18/1', '19/1', '20/1', '21/1', '22/1'],
    },
    randomUser: {
      username: '',
      password: ''
    },
    lastGenerateUser: undefined,
  }),

  computed: {},

  watch: {},

  created() {
    this.checkAuthority('admin')
    this.randomUser.username = this.generateRandomString();
    this.randomUser.password = this.generateRandomString();
  },

  mounted() {
    store.state.loading = false;
  },

  methods: {
    checkAuthority(requiredAuth) {
      let auths = token.getAuthority()
      this.auth = auths.indexOf(requiredAuth) !== -1
    },
    generateRandomString() {
      const letters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * letters.length);
        result += letters[randomIndex];
      }
      return result;
    },
    generate(){
      User.generateUser(this.randomUser, res => {
        this.lastGenerateUser = res;
      })
    }
  },
}
</script>

<style scoped>

</style>
