import {initializeApp} from "firebase/app";
import {getStorage, ref, uploadBytesResumable, getDownloadURL} from "firebase/storage";
import {v4 as uuid} from 'uuid';

const firebaseConfig = {
    apiKey: "AIzaSyDgl19HwLqXQLD0ydxTNV5K6Kxuafo5aeE",
    authDomain: "brave-set-328106.firebaseapp.com",
    projectId: "brave-set-328106",
    storageBucket: "brave-set-328106.appspot.com",
    messagingSenderId: "948818650200",
    appId: "1:948818650200:web:91805ff61325ecffef162d",
    measurementId: "G-GYTNP78P5W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

// Points to the root reference
const storageRef = ref(storage);

function uploadAvatar(file, progressFunc, finishFunc, errorFunc) {
    let path = 'images/avatars/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc);
}

function uploadQuestionPicture(file, progressFunc, finishFunc, errorFunc) {
    let path = 'images/questions/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc);
}

function uploadQuestionFile(file, progressFunc, finishFunc, errorFunc) {
    let path = 'files/questions/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc);
}

function uploadCourseCover(file, progressFunc, finishFunc, errorFunc){
    let path = 'images/courseCover/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc);
}

function uploadCourseVideo(file, progressFunc, finishFunc, errorFunc){
    let path = 'videos/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc)
}

function uploadCourseSubtitle(videoId, file, progressFunc, finishFunc, errorFunc){
    let path = 'subtitles/'+videoId+'/'+uuid();
    uploadFile(path, file, progressFunc, finishFunc, errorFunc)
}

function getBase64(file, func) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        func(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

function uploadFile(path, file, progressFunc, finishFunc, errorFunc){
    const reference = ref(storageRef, path);
    let uploadTask = uploadBytesResumable(reference ,file);
    uploadTask.on('state_changed',
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            if (progressFunc == null){
                return;
            }
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressFunc(progress)
        },
        (error) => {
            // Handle unsuccessful uploads
            if (errorFunc == null){
                return;
            }
            errorFunc(error)
        },
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                finishFunc(downloadURL)
            });
        }
    );
}

export default {uploadAvatar, getBase64, uploadCourseCover, uploadCourseVideo, uploadCourseSubtitle, uploadQuestionPicture, uploadQuestionFile}
