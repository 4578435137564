<template>
  <v-container>
    <div v-if="success">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-img src="../../src/assets/email-success.gif" max-width="400"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-h2">{{$i18n.t("Email Address Validated!")}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-overline">{{$i18n.t("You can close this page anytime.")}}</div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-h2">{{$i18n.t("Your email address does not validated!")}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-overline align-center">{{$i18n.t("This may cause by expired email validation link.")}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="text-overline align-center">{{$i18n.t("Please resend the validation email!")}}<br/></div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "EmailValidationPage",
  data(){
    return {
      success: undefined
    }
  },
  created() {
    this.success = this.$route.query.success === 'true'
  },
  beforeCreate() {
    this.$store.state.style.select.mode = false
    this.$vuetify.theme.dark = false
  },
  beforeDestroy() {
    this.style(this.$store.state.style.select.mode)
  },
  methods: {
    style: function (mode) {
      if (mode === 'auto') {
        localStorage.setItem('theme', 'auto')
        this.$vuetify.theme.dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches
      } else {
        localStorage.setItem('theme', mode)
        this.$vuetify.theme.dark = mode
      }
    },
  }
}
</script>

<style scoped>

</style>
