<template>
  <v-app-bar app color="blue" dark light>
    <v-toolbar color="blue" dark light flat>
      <v-toolbar-title>{{ $i18n.t($route.meta.page) }}</v-toolbar-title>
      <v-spacer/>
      <v-btn
          icon
          @click="gotoNotification"
          v-if="$route.path !== '/document'
          && $route.path !== '/email/validation'"
      >
        <v-badge
            color="red"
            :content="this.$store.state.notifications.length"
            :value="this.$store.state.notifications.length"
            overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
      <SiteSetting v-if="$route.path !== '/document'"/>
    </v-toolbar>
  </v-app-bar>
</template>

<script>
import SiteSetting from "@/components/SiteSetting";

export default {
  name: "AppBar",
  components: {SiteSetting},
  data() {
    return {}
  },
  created() {
  },
  methods: {
    gotoNotification() {
      if (this.$route.path !== '/notification') {
        this.$router.push({path: '/notification'})
      }
    }
  }
}
</script>

<style scoped>

</style>
