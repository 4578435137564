import store from '../store'
import axios from "axios";
// import device from "@/utils/device";
// import token from "@/utils/token";
import api from "@/store/api";
import snackBar from "@/utils/SnackBar";
import i18n from "@/i18n";
import token from "@/utils/token";

function login(username, password, func) {

    const instance = axios.create({
        baseURL: api.OAUTH_URL,
        timeout: 300000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic cG9ydGFsOlFEV3F3ZHFXRHF3ZDIzcmYydzRHdjR3cmd2V0VnYnZocjU='
        }
    })

    instance.post(
        api.AUTH_LOGIN + "?grant_type=password&scope=all&username="+encodeURIComponent(username)+"&password="+encodeURIComponent(password),
        {},
    ).then((result) => {
        if (result.data == null || result.data === "") {
            snackBar.Launch(i18n.t(store.state.LoginMsg.LoginError))
            return
        }
        if (result.status !== 200){
            snackBar.Launch(JSON.parse(JSON.parse(JSON.stringify(result.data)).message).error_description)
            return
        }
        let data = result.data
        store.state.user.token.access = data.access_token
        store.state.user.token.refresh = data.refresh_token

        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('refresh_token', data.refresh_token)
        // if (token.getAuthority().indexOf('teacher') === -1 && token.getAuthority().indexOf('admin') === -1) {
        //     snackBar.Launch("You don't have permission to access the panel!")
        //     return
        // }
        store.state.user.status = true
        const auths = token.getAuthority();
        if (auths.indexOf("admin") > -1 || auths.indexOf("teacher") > -1 || auths.indexOf("god") > -1) {
            store.state.user.isStudent = false
        }
        func(result)
    }).catch(result => {
        snackBar.Launch(i18n.t(store.state.LoginMsg.ConnErr) + ':' + result.message)
        }
    )
}

export default {login}
