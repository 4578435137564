<template>
  <v-dialog v-model="$store.state.viewingAccountSettings" max-width="702px"
            onclose="$store.state.viewingAccountSettings = null">
    <v-card>
      <v-card-title class="pb-5">
        <div class="text-h5">{{$i18n.t("User Settings")}}</div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" lg="3">
              <div class="text-lg-center pt-2">{{$i18n.t("Authority")}}</div>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-btn-toggle
                  v-model="authSelected"
                  multiple
                  color="primary"
              >
                <v-btn
                    v-for="item in auth"
                    :key="item"
                    :value="item"
                    tile
                >
                  {{ item }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" lg="3">
              <div class="text-lg-center pt-2">{{$i18n.t("Account State")}}</div>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-btn-toggle
                  v-model="stateSelected"
                  color="primary"
                  mandatory
              >
                <v-btn
                    v-for="item in states"
                    :key="item"
                    :value="item"
                    tile
                >
                  {{ item }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" lg="3">
              <div class="text-lg-center pt-2">{{$i18n.t("Course Manager")}}</div>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-select
                  :items="courseList"
                  item-text="name"
                  item-value="_id"
                  v-model="selectedCourse"
                  multiple
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="12" sm="12" lg="6">
              <v-btn block @click="$store.state.viewingAccountSettings = null">{{$i18n.t("Cancel")}}</v-btn>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
              <v-btn block @click="save">{{$i18n.t("Save")}}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import User from "@/utils/User";
import snackBar from "@/utils/SnackBar";
import course from "@/utils/Course";

export default {
  name: "AdminUserSettings",
  data() {
    return {
      authSelected: this.$store.state.viewingAccountSettings.authorities,
      stateSelected: '',
      selectedCourse: [],
      auth: ['normal', 'teacher', 'admin', 'god'],
      states: ['enable', 'expired', 'locked'],
      courseList: []
    }
  },
  created() {
    if (this.$store.state.viewingAccountSettings.isEnabled === 'true') {
      this.stateSelected = 'enable'
    } else if (this.$store.state.viewingAccountSettings.isExpired === 'true') {
      this.stateSelected = 'expired'
    } else if (this.$store.state.viewingAccountSettings.isLocked === 'true') {
      this.stateSelected = 'locked'
    }
    this.selectedCourse = this.$store.state.viewingAccountSettings.courses
    course.getAllCourse(100000, 0, res => {
      this.courseList = res.entities
    })
  },
  methods: {
    save() {
      let res = {
        _id: this.$store.state.viewingAccountSettings._id,
        authorities: this.authSelected,
        isEnabled: this.stateSelected === 'enable',
        isExpired: this.stateSelected === 'expired',
        isLocked: this.stateSelected === 'locked'
      }
      // let selectedCourseId = []
      // this.selectedCourse.forEach((item) => {
      //   selectedCourseId.push(this.courseId[item])
      // })
      User.updateUserCourse(this.$store.state.viewingAccountSettings._id, this.selectedCourse, () => {
      })
      User.updateUserAuth(res, (res) => {
        snackBar.Launch(res)
        this.$store.state.viewingAccountSettings = null
      })
    }
  }
}
</script>

<style scoped>

</style>
