<template>
  <div>
    <v-container v-if="!$store.state.user.status" class="pt-16">
      <v-row justify="center">
        <v-img src="../assets/no-access.svg" max-width="200px" max-height="200px"/>
        <v-col cols="12">
          <div class="text-center pt-6 text-h4 text--darken-1">{{$i18n.t("Please Login First!")}}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col class="mx-auto" cols="12" lg="6" md="12" sm="12">
          <v-card class="pa-6 mt-2">
            <v-card-title class="pb-8">
              <v-col cols="12" lg="12" md="8" sm="12">
                {{$i18n.t("Personal Information")}}
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mx-auto">
                  <v-spacer/>
                  <AvatarCropper class="pr-5"/>
                  <v-spacer/>
                </v-row>
                <v-form :disabled="!edit" class="pt-16">
                  <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-text-field label="First Name" filled v-model="user.firstName"></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-text-field label="Last Name" filled v-model="user.lastName"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-combobox label="Gender" filled v-model="user.gender" :items="genders"></v-combobox>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-combobox label="Region" filled v-model="user.country" :items="countries"></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12">
                      <v-textarea label="Description" filled v-model="user.description"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="12" md="12" sm="12">
                      <v-btn @click="edit = true" v-if="$store.state.user.status && !edit" block class="primary">
                        <v-icon left>mdi-pencil</v-icon>
                        {{$i18n.t("Edit")}}
                      </v-btn>
                      <v-row>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-btn @click="cancel" v-if="$store.state.user.status && edit" block class="error">
                            <v-icon left>mdi-pencil</v-icon>
                            {{$i18n.t("Cancel")}}
                          </v-btn>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-btn @click="submit" v-if="$store.state.user.status && edit" block class="success">
                            <v-icon left>mdi-pencil</v-icon>
                            {{$i18n.t("Save")}}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mx-auto" cols="12" lg="6" md="12" sm="12">
          <v-card class="pa-6 mt-3">
            <v-card-title>
              <v-col cols="12" lg="12" md="8" sm="12">
                 {{$i18n.t("Security Settings")}}
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" lg="8" md="7" sm="12">
                    <v-text-field label="Mobile" filled v-model="user.phoneNumber" :disabled="!editPhoneNumber"/>
                  </v-col>
                  <v-col cols="12" lg="4" md="5" sm="12">
                    <v-btn @click="editPhoneNumber = true" v-if="$store.state.user.status && !editPhoneNumber" block
                           class="primary" height="56">
                      <v-icon left>mdi-pencil</v-icon>
                      {{$i18n.t("Update")}}
                    </v-btn>
                    <ValidateCode v-if="$store.state.user.status && editPhoneNumber"
                                  v-bind:phoneNumber="user.phoneNumber"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="8" md="7" sm="12">
                    <v-text-field label="Email" filled v-model="user.email" :disabled="!editEmail"
                                  append-icon="mdi-edit" type="email"/>
                  </v-col>
                  <v-col cols="12" lg="4" md="5" sm="12">
                    <v-btn @click="validateEmail"
                           v-if="($store.state.user.email !== 'null' && $store.state.user.email !== '') && !$store.state.user.emailValidated || editEmail"
                           block class="warning"
                           height="56">
                      <v-icon left>mdi-email-edit-outline</v-icon>
                      {{$i18n.t("Verify")}}
                    </v-btn>
                    <v-btn @click="editEmail = true"
                           v-else
                           block class="primary"
                           height="56">
                      <v-icon left>mdi-pencil</v-icon>
                      {{$i18n.t("Update")}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="12" md="12" sm="12">
                    <UpdatePassword/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import anime from "animejs";
import store from "@/store";
import country from "@/store/country"
import user from "@/utils/User";
import ValidateCode from "@/components/ValidateCode";
import verify from "@/utils/verify";
import UpdatePassword from "@/components/UpdatePassword";
import AvatarCropper from "@/components/AvatarCropper";

export default {
  name: "Personal",
  components: {AvatarCropper, ValidateCode, UpdatePassword},
  data() {
    return {
      edit: false,
      loading: false,
      editEmail: false,
      editPhoneNumber: false,
      changePhoneNumberDialog: false,
      user: store.state.user,
      countries: country.getCountries(),
      photo: null,
      genders: [
        "Male",
        "Female",
        "Non-Binary"
      ],
      uploadProgress: 0
    }
  },
  created() {
    // console.log(this.user)
  },
  methods: {
    submit() {
      this.edit = false;
      user.updateUserDetails(this.user)
    },
    cancel() {
      this.edit = false;
      user.getUserDetails()
    },
    validateEmail() {
      let email = this.user.email;
      const reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
      if (email !== '' && reg.test(email)) {
        verify.verifyEmail(email)
      }
      this.editEmail = false
    },
    setProfilePhoto(photo) {
      this.photo = photo;
    },
  },
  watch: {
    "$store.state.user": {
      deep: true,
      handler: function () {
        this.user = store.state.user
      }
    },
  },
  mounted() {
    setTimeout(() => {
      try {
        anime({
          targets: '.card-translate-up',
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(75),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-translate-up h1', '.card-translate-up h2', '.card-translate-up p', '.card-translate-up span:not(.v-btn__content)'],
          translateY: [48, 0],
          opacity: [0, 1],
          duration: 625,
          delay: anime.stagger(25),
          easing: "easeOutQuint"
        })
        anime({
          targets: ['.card-fade-appear'],
          scaleY: [1.1, 1],
          duration: 325,
          easing: "easeOutQuint"
        })
      } catch (e) {
        console.warn("HomeAnimation", "error when animating home entry animation", e)
      }
    }, 0);
    store.state.loading = false;
  }
}
</script>

<style scoped>
</style>
