<template>
  <v-card max-width="400px">
    <v-card-title class="pa-5">
      <span class="text-h5">{{$i18n.t("Upload Picture")}}</span>
    </v-card-title>
    <v-card-text>
      <v-overlay v-show="uploading" absolute>
        <v-card width="300">
          <v-card-title>
            {{$i18n.t("Uploading...")}}
          </v-card-title>
          <v-card-text>
            <v-progress-linear rounded :value="uploadProgress" height="25">
              <strong>{{ Math.ceil(uploadProgress) }}%</strong>
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
      <!-- 待上传图片 -->
      <div v-show="!options.imageFile">
        <v-file-input
            v-model="options.imageFile"
            accept="image/jpg, image/jpeg, image/png"
        />
        <div>{{$i18n.t("Support jpg/png pictures and size under 5MB")}}</div>
      </div>
      <!-- 已上传图片 -->
      <div v-show="options.imageFile">
        <v-row>
          <v-col cols="12">
            <cropper
                class="crop-box crop-warp"
                ref="cropper"
                :src="options.img"
                :stencil-props="stencilProps"
                @change="change"
            ></cropper>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-show="options.imageFile">
      <v-btn @click="reupload">
        {{$i18n.t("Reselect")}}
      </v-btn>
      <v-btn @click="doUpload">
        {{$i18n.t("Upload")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import firebase from "@/utils/firebase";

export default {
  name: "PictureUploader",
  components: {Cropper},
  data() {
    return {
      selectedPhoto: null,
      // vueCropper组件 裁剪配置信息
      options: {
        imageFile: null,
        img: '', // 原图文件
        croppedImage: '', // 剪过的图片
        coordinates: null
      },
      uploadProgress: 0,
      uploading: false
    }
  },
  props: {
    upload: {
      type: Function
    },
    stencilProps: {
      type: Object
    }
  },
  methods: {
    doUpload() {
      const isIMAGE = this.options.croppedImage.type === 'image/jpeg' || this.options.croppedImage.type === 'image/png'
      const isLt5M = this.options.croppedImage.size / 1024 / 1024 < 5
      if (!isIMAGE) {
        return false
      }
      if (!isLt5M) {
        return false
      }
      this.uploading = true
      firebase.uploadAvatar(this.options.croppedImage, (progress) => {
        this.uploadProgress = progress
      }, (result) => {
        this.uploading = false
        this.dialog = false
        this.uploadProgress = 0
        this.options.img = null
        this.options.imageFile = null
        this.upload(result)
      }, (error) => {
        this.uploading = false
        this.uploadProgress = 0
        console.log(error)
      })
    },
    // 重新上传
    reupload() {
      this.options.imageFile = null
    },
    change({coordinates, canvas}) {
      this.options.coordinates = coordinates
      canvas.toBlob((file) => {
        this.options.croppedImage = file
      }, 'image/jpeg', 0.95)
    },
  },
  watch: {
    "options.imageFile": {
      deep: true,
      handler: function () {
        if (this.options.imageFile !== null) {
          firebase.getBase64(this.options.imageFile, (image) => {
            this.options.img = image
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.crop-warp {
  max-height: calc(100vh);
  max-width: 100%;
}
</style>
