import axios from "@/utils/axios";
import api from "@/store/api";

function addDailyPost(data, func) {
    data.content = btoa(data.content)
    axios.post(
        api.DAILYPOST_ADD,
        data
    ).then(() => {
        func()
    }).catch((error) => {
        console.log(error)
    })
}

function getDailyPost(id, func) {
    axios.get(
        api.DAILYPOST_GET_ONE + id,
    ).then((res) => {
        res.content = atob(res.content)
        func(res)
    }).catch((error) => {
        console.log(error)
    })
}

function getDailyPostList(limit, page, sort, func) {
    axios.get(
        api.DAILYPOST_GET_LIST + "?limit=" + limit + "&page=" + page + "&sort=" + sort,
    ).then((res) => {
        func(res.data.data)
    }).catch((error) => {
        console.log(error)
    })
}

function deleteDailyPost(id, func) {
    axios.post(
        api.DAILYPOST_DELETE + '?_id=' + id
    ).then(() => {
        func()
    }).catch((error) => {
        console.log(error)
    })
}

function updateDailyPost(data, func) {
    data.content = btoa(data.content)
    axios.post(
        api.DAILYPOST_UPDATE,
        data
    ).then(() => {
        func()
    }).catch((error) => {
        console.log(error)
    })
}

export default {
    addDailyPost,
    getDailyPost,
    getDailyPostList,
    deleteDailyPost,
    updateDailyPost
}
