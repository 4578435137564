<template>
  <MemberManager/>
</template>

<script>
import MemberManager from "@/components/MemberManager";

export default {
  name: "Member",
  components: {MemberManager}
}
</script>

<style scoped>
</style>
