<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          text
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-pencil</v-icon>
        {{$i18n.t("Manage")}}
      </v-btn>
    </template>
    <v-card>
      <v-tabs grow v-model="tab">
        <v-tab>
          {{$i18n.t("Course Details")}}
        </v-tab>
        <v-tab>
          {{$i18n.t("Modules and Lessons")}}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <CourseDetailsForm :form-data="item"/>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <CourseModuleForm :form-data="item"/>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn block @click="close">{{$i18n.t("Cancel")}}</v-btn>
            </v-col>
            <v-col>
              <v-btn block @click="save">{{$i18n.t("Save")}}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CourseDetailsForm from "@/components/course/CourseDetailsForm";
import CourseModuleForm from "@/components/course/CourseModuleForm";
import course from "@/utils/Course";

export default {
  name: "CourseEditDialog",
  props: ["item"],
  components: {CourseDetailsForm, CourseModuleForm},
  data() {
    return {
      tab: null,
      dialog: false
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      try {
        let s = atob(this.item.desText)
        this.item.desText = s
        // eslint-disable-next-line no-empty
      }catch (e){
      }
      course.updateCourse(this.item, () => {
        this.close()
      })
    }
  }
}
</script>

<style scoped>

</style>
