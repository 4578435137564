import {v4 as uuidv4} from "uuid";
import key from "@/utils/Key";

function getBrowser() {
    let types = ['edge', 'firefox', 'chrome', 'safari', 'opera ']
    let userAgent = navigator.userAgent.toLocaleLowerCase()
    var res = []
    types.forEach(element => {
        if (userAgent.indexOf(element) > 0) {
            let rule = `${element}` + "\\/([\\d.]+)"
            res.push(element)
            res.push(userAgent.match(rule)[1])
        }
    })
    if (res.indexOf('chrome') > -1 && res.indexOf('safari') > -1) {
        if (res.length === 4) {
            let temp = []
            temp.push('chrome')
            temp.push(res[res.indexOf('chrome') + 1])
            res = temp
        } else {
            res.splice(res.indexOf('chrome'), 2)
            res.splice(res.indexOf('safari'), 2)
        }
    }
    return res.join(" ")
}

function getDeviceEntity() {
    const uuid = uuidv4();
    const deviceName = getBrowser();
    return {deviceID: uuid, deviceName: deviceName, deviceValidCode: key.Get(uuid, deviceName)};
}

export default {getDeviceEntity}
