function exportAsCsv(items, filename) {
    // 表头
    const exportTitle = Object.keys(items[0]);

    let row = "",
        csvData = "";
    for (const title of exportTitle) {
        if (title === 'auths') {
            continue
        }
        row += '"' + title + '",';
    }
    csvData += row + "\r\n"; // 添加换行符号
    for (const item of items) {
        row = "";
        for (let key in item) {
            if (key === 'auths') {
                continue
            }
            row += '"' + JSON.stringify(item[key]).replaceAll("\"", "") + '",';
        }
        csvData += row + "\r\n"; // 添加换行符号
    }
    if (!csvData) return;

    let alink = document.createElement("a");
    // 解决中文乱码的问题，标识该字节流的字节序
    let _utf = "\uFEFF";
    const bw = browser();
    if (bw["edge"] || !bw["ie"]) {
        // Blob IE>=10 都支持，不能作为判断依据
        if (window.Blob && window.URL && window.URL.createObjectURL) {
            // DOMStrings会被编码为UTF-8，utf-8保存的csv格式要让Excel正常打开的话，必须加入在文件最前面加入BOM(Byte order mark)
            const csvDataBlob = new Blob([_utf + csvData], {
                type: "text/csv",
            });
            //会产生一个类似 blob:http://localhost:8083/5a2d03ec-cbdf-4ea9-be0c-f837ed35a9be 这样的URL字符串,
            //可以像使用普通 URL 那样使用它，比如用在 img.src 上。
            alink.href = URL.createObjectURL(csvDataBlob);
        }
        document.body.appendChild(alink);
        alink.setAttribute("download", filename);
        alink.click();
        document.body.removeChild(alink);
    } else if (bw["ie"] >= 10) {
        const csvDataBlob = new Blob([_utf + csvData], {
            type: "text/csv",
        });
        navigator.msSaveBlob(csvDataBlob, filename);
    }
}

function browser() {
    let Sys = {};
    let ua = navigator.userAgent.toLowerCase();
    let s;
    // eslint-disable-next-line no-cond-assign
    (s = ua.indexOf("edge") !== -1 ? (Sys.edge = "edge") : ua.match(/rv:([\d.]+)\) like gecko/)) ? (Sys.ie = s[1]) : (s = ua.match(/msie ([\d.]+)/)) ? (Sys.ie = s[1]) : (s = ua.match(/firefox\/([\d.]+)/)) ? (Sys.firefox = s[1]) : (s = ua.match(/chrome\/([\d.]+)/)) ? (Sys.chrome = s[1]) : (s = ua.match(/opera.([\d.]+)/)) ? (Sys.opera = s[1]) : (s = ua.match(/version\/([\d.]+).*safari/)) ? (Sys.safari = s[1]) : 0;
    return Sys;
}

export default {
    exportAsCsv
}
