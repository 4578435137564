<template>
  <v-dialog v-model="$store.state.viewingTokenSettings" max-width="522px"
            onclose="$store.state.viewingTokenSettings = null">
    <v-card>
      <v-card-title class="pb-5">
        <div class="text-h5">{{$i18n.t("User Content Management")}}</div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex align-center">
              <p class="text-h5 text-center">{{$i18n.t("Token")}}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
              <v-row>
                <v-col class="d-flex justify-end align-center">
                  <v-btn icon x-large @click="minOneToken" :disabled="tokenNumber<=0">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="d-flex justify-center align-center">
                  <v-text-field v-model="tokenNumber" type="number" :rules="[tokenRule]" dense class="centered-input"/>
                </v-col>
                <v-col class="d-flex justify-start align-center">
                  <v-btn icon x-large @click="addOneToken">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="12" sm="12" lg="6">
              <v-btn block @click="$store.state.viewingTokenSettings = null">{{$i18n.t("Cancel")}}</v-btn>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
              <v-btn block @click="save">{{$i18n.t("Save")}}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import User from "@/utils/User";
// import snackBar from "@/utils/SnackBar";

export default {
  name: "AdminTokenSettings",
  data() {
    return {
      tokenNumber: 0
    }
  },
  created() {
    this.tokenNumber = this.$store.state.viewingTokenSettings.token
  },
  methods: {
    save() {
      User.updateUserTokenNumber(this.$store.state.viewingTokenSettings._id, this.tokenNumber, ()=>{
        this.$store.state.viewingTokenSettings = null
      })
    },
    tokenRule(num){
      if (num < 0){
        return "Token should not be negative"
      }
      return true
    },
    addOneToken(){
      this.tokenNumber = parseInt(this.tokenNumber)
      this.tokenNumber += 1
    },
    minOneToken(){
      this.tokenNumber = parseInt(this.tokenNumber)
      this.tokenNumber -= 1
    }
  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>
