<template>
  <div>
    <v-card>
      <v-card-title>
        {{this.item == null ? $i18n.t("Add An Question"): $i18n.t("Edit An Question")}}
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row class="pl-3">
            <v-col cols="5">
              <v-select label="Part" v-model="dataForm.part" :items="part[exercise.group]"/>
            </v-col>
          </v-row>
          <div v-if="exercise.group === 'Reading' || exercise.group === 'Listening'">
            <v-row justify="center">
              <v-col cols="11">
              <strong>{{$i18n.t("Question Material")}}:</strong>
              </v-col>
            </v-row>
            <v-row class="my-5" justify="center">
              <RichEditor @setText="setQuestionMaterial" :editor-text="dataForm.material"/>
            </v-row>
          </div>
          <v-row justify="center">
            <v-col cols="11">
              <strong>{{$i18n.t("Question Text")}}:</strong>
            </v-col>
          </v-row>
          <v-row class="my-5" justify="center">
            <RichEditor @setText="setQuestionText" :editor-text="dataForm.text"/>
          </v-row>
          <v-row>
            <v-btn class="success" @click="submitQuestion" :disabled="this.dataForm.text === ''">{{$i18n.t("Submit")}}</v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RichEditor from "@/components/RichEditor";
import exercise from "@/utils/exercise";

export default {
  name: "QuestionForm",
  components: {RichEditor},
  data() {
    return {
      dialog: false,
      part: {
        Listening: [1, 2, 3, 4],
        Reading: [1, 2, 3],
        Speaking: [1, 2, 3],
        Writing: [1, 2]
      },
      dataForm: {
        material: '',
        text: '',
        part: 1
      }
    }
  },
  props: ["exercise", "item"],
  created() {
    if (this.item !== null){
      this.dataForm = this.item
      this.dataForm.material = atob(this.item.material)
      this.dataForm.text = atob(this.item.text)
    }
  },
  methods: {
    setQuestionMaterial(text){
      this.dataForm.material = text
    },
    setQuestionText(text){
      this.dataForm.text = text
    },
    submitQuestion(){
      if (this.dataForm.text === ''){
        return;
      }
      this.dataForm.text = btoa(this.dataForm.text)
      this.dataForm.material = btoa(this.dataForm.material)
      if (this.dataForm._id === undefined){
        exercise.addQuestion(this.exercise._id, this.dataForm, res => {
          this.$emit('refresh', res)
        })
      }else {
        exercise.updateQuestion(this.dataForm, ()=> {
          this.$emit('back')
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
