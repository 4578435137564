<template>
  <div>
    <div class="tinymce-editor" v-if="!uploading">
      <editor
          v-model="tinymceHtml"
          class="tinymce"
          api-key="no-api-key"
          :init="editorInit"
          output-format="html"
          :key="tinymceFlag"
      />
    </div>
    <v-overlay v-else>
      <v-col>
        <strong>{{$i18n.t("Uploading...")}}</strong>
        <v-progress-linear rounded :value="uploadProgress" height="25">
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
      </v-col>
    </v-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import tinymce from "tinymce/tinymce";
/* eslint-enable */
import Editor from '@tinymce/tinymce-vue'
import firebase from "@/utils/firebase";
import 'tinymce/icons/default/icons' // 解决了icons.js 报错Unexpected token '<'
// 引入富文本编辑器主题的js和css
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/skins/ui/oxide/skin.min.css'
// 扩展插件
import 'tinymce/plugins/lists'
import 'tinymce/plugins/image'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/link'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/autoresize'
// 务必将目录src/assets/plugins/tinymce/plugins下的importword,upfile和insertjson文件夹放入node_modules/tinymce/plugins
import 'tinymce/plugins/importword'
import 'tinymce/plugins/upfile'
import 'tinymce/plugins/insertjson'

export default {
  name: "RichEditor",
  components: {
    Editor
  },
  data() {
    return {
      tinymceFlag: 1,
      uploading: false,
      uploadProgress: 0,
      tinymceHtml: this.editorText,
      editorInit: {}
    }
  },
  props: {
    editorHeight: {
      default: 400,
      type: Number
    },
    editorText: {
      default: 'Please type here...',
      type: String
    }
  },
  mounted() {
    this.editorInit = {
      selector: '.tinymce', // 容器
      // skin_url: '/tinymce/skins/ui/oxide', // 主题
      height: this.editorHeight,
      plugins: 'link lists image code table wordcount importword upfile autoresize imagetools insertjson', // 用到的插件：列表、图片、代码块、表格、字数
      toolbar: 'undo redo | bold italic underline strikethrough | formatselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | table image upfile insertjson | importword removeformat',
      fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
      // 工具栏
      statusbar: true, // 底部的状态栏
      menubar: true, // 最上方的菜单
      branding: false, // 水印“Powered by TinyMCE”
      max_height: 1000,
      min_height: 400,
      images_upload_handler: function (blobInfo, succFun, failFun) {
        var file = blobInfo.blob();
        firebase.uploadQuestionPicture(file, null, succFun, failFun)
      },
      file_callback: function (file, succFun) {
        // 自定义处理文件操作部分
        firebase.uploadQuestionFile(file,
            (progress, uploaded, total) => {
              this.uploadProgress = progress
              this.uploadedBytes = uploaded
              this.totalBytes = total
            },
            (res) => {
              succFun(res, {text: file.name})
              this.loading = false
            },
            (err) => {
              this.loading = false
              console.log(err)
            })
      },
      init_instance_callback: editor => {
        this.formEditor = editor;
        if (this.tinymceHtml) {
          editor.setContent(this.tinymceHtml, {format: "html"});
        }
        // editor.on("change", () => {
        //   console.log(this.tinymceContent);
        // });
      }
      // file_picker_types: 'file image media',
      // file_picker_callback: function (callback) {
      //   //文件分类
      //   var filetype = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
      //
      //   var input = document.createElement('input');
      //   input.setAttribute('type', 'file');
      //   input.setAttribute('accept', filetype);
      //   input.click();
      //   input.onchange = function () {
      //     var file = this.files[0];
      //     this.uploading = true
      //
      //     firebase.uploadQuestionFile(file,
      //         (progress, uploaded, total) => {
      //           this.uploadProgress = progress
      //           this.uploadedBytes = uploaded
      //           this.totalBytes = total
      //         },
      //         (res) => {
      //           callback(res, {text: file.name})
      //           this.loading = false
      //         },
      //         (err) => {
      //           this.loading = false
      //           console.log(err)
      //         })
      //   }
      // }
    };//初始化编辑器
    this.tinymceFlag++;
  },
  watch: {
    "tinymceHtml": {
      deep: true,
      handler: function () {
        this.$emit('setText', this.tinymceHtml.replaceAll('\\"', '\\\\"'))
      }
    }
  },
  beforeDestroy() {
    // 销毁组件前销毁编辑器
    this.formEditor && this.formEditor.destroy();
  },
  activated() {
    // 每次都给编辑器改变不同的key值使其每次切换页面都重新加载
    this.tinymceFlag++;
  },
}
</script>

<style scoped>

</style>
