<template>
  <v-card>
    <v-card-title class="pb-5">
      <div class="text-h5">{{ $i18n.t("Chat with user") }}</div>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <DynamicScroller
                class="scroller"
                :min-item-size="10"
                :items="notificationList"
                v-if="notificationList.length > 0"
                keyField="_id"
                @scroll-end="loadMore"
            >
              <template v-slot:default="{ item, active, index }">
                <v-container>
                  <v-row>
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :size-dependencies="[
                            item.content,
                          ]"
                        :data-index="index"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="auto" class="align-self-center">
                            <v-avatar size="30">
                              <v-img :src="item.senderId === $store.state.user._id ? $store.state.user.photoUrl : receiverAvatar"></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col cols="auto" class="align-self-center">
                            <strong>{{item.senderId === $store.state.user._id ? $store.state.user.firstName + ' ' + $store.state.user.lastName : receiverName}}</strong>
                          </v-col>
                        </v-row>
                        <v-row class="align-center">
                          <v-col cols="auto">
                            <div class="text-container" v-for="(line, index) in splitText(item.content)" :key="index" style="white-space: pre-line;" v-html="line"/>
                            <div>
                              {{ new Date(item.createDate).toLocaleString() }}
                              <v-icon :color="item.read ? 'green' : 'red'">
                                {{ item.read ? "mdi-check-all" : "mdi-check" }}
                              </v-icon>
                              {{ item.read ? "Read" : "Sent" }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </DynamicScrollerItem>
                  </v-row>
                  <v-row>
                    <v-divider/>
                  </v-row>
                </v-container>
              </template>
            </DynamicScroller>
            <v-skeleton-loader
                v-bind="attrs"
                type="article"
                v-else-if="loading"
            ></v-skeleton-loader>
            <div class="text-center text-h5" v-else>
              <v-icon large>mdi-chat-remove</v-icon>
              No Records
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-textarea v-model="sendText" placeholder="Type your message here..." outlined height="100"></v-textarea>
          </v-col>
          <v-col cols="2">
            <v-btn @click="send" color="primary" height="100" block>Send</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import User from "@/utils/User";
import SnackBar from "@/utils/SnackBar";
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import {DynamicScroller, DynamicScrollerItem} from "vue-virtual-scroller";

export default {
  name: "ChatDialog",
  components: {DynamicScroller, DynamicScrollerItem},
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 0,
      },
      senderId: this.$store.state.user._id,
      sendText: "",
      notificationList: [],
      loadingList: [],
      count: 0,
      loading: false,
      page: 1,
      size: 10,
      loadingMore: true,
      receiverName: '',
      receiverAvatar: '',
      height: 0
    }
  },
  props: ["receiverId"],
  created() {
    this.getAllNotification(1)
    User.getUserPublicDetails(this.receiverId, (user) => {
      this.receiverName = user.firstName + " " + user.lastName
      this.receiverAvatar = user.photoUrl
    })
  },
  watch: {
    receiverId: function (newVal, oldVal) {
      if (newVal === oldVal) return
      this.notificationList = []
      this.page = 1
      this.loadingMore = true
      this.getAllNotification(1)
      User.getUserPublicDetails(newVal, (user) => {
        this.receiverName = user.firstName + " " + user.lastName
        this.receiverAvatar = user.photoUrl
      })
    },
    loadingMore: function (newVal, oldVal) {
      if (oldVal){
        this.notificationList.sort((a, b) => b.createDate - a.createDate)
      }
    },
    notificationList: function () {
      this.notificationList.forEach((item) => {
        if (item.senderId !== this.$store.state.user._id && !item.read) {
          User.readNotification(item._id, ()=> {
            item.read = true
            this.$emit("read", item.senderId)
          })
        }
      })
    }
  },
  mounted() {
    this.height = window.innerHeight
  },
  methods: {
    send() {
      if (this.sendText === "") {
        SnackBar.lunch("Message cannot be empty")
        return
      }
      User.sendNotification(
          {
            receivers: [this.receiverId],
            content: this.sendText,
            title: "New message from " + this.$store.state.user.firstName + " " + this.$store.state.user.lastName,
          },
          () => {
            this.sendText = ""
            this.notificationList = []
            this.getAllNotification(1)
          }
      )
    },
    splitText(text) {
      return text.split('\n');
    },
    getAllNotification(page) {
      if (this.loading) return
      this.loading = true
      let data = []
      User.getAllNotification(this.$store.state.user._id, this.receiverId, page, 10, (list) => {
        data = data.concat(list.data);
        if (this.receiverId !== this.$store.state.user._id) {
          User.getAllNotification(this.receiverId, this.$store.state.user._id, page, 10, (list) => {
            data = data.concat(list.data);
            this.notificationList = data.sort((a, b) => b.createDate - a.createDate);
            this.loading = false
          })
        } else {
          this.notificationList = data.sort((a, b) => b.createDate - a.createDate);
          this.loading = false
        }
      })
    },
    loadMore() {
      if (this.loading) {
        return
      }
      this.page++
      let data = []
      User.getAllNotification(this.$store.state.user._id, this.receiverId, this.page, 10, (list) => {
        data = data.concat(list.data);
        if (this.receiverId === this.$store.state.user._id) {
          this.notificationList.concat(data).sort((a, b) => b.createDate - a.createDate);
          return
        }
        User.getAllNotification(this.receiverId, this.$store.state.user._id, this.page, 10, (list) => {
          data = data.concat(list.data);
          if (data.length === 0){
            this.loadingMore = false
          }
          this.notificationList.concat(data).sort((a, b) => b.createDate - a.createDate);
        })
      })
    }
  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

.scroller {
  max-height: 400px;
}

.text-container {
  max-width: calc(100%);
}
</style>
