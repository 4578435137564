<template>
  <v-dialog v-model="dialog" v-if="$store.state.user.status" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-container v-show="!$store.state.nav.mini">
        <v-row class="d-flex align-center">
          <v-col cols="9">
            <v-btn  v-bind="attrs" v-on="on" text x-large>
              <v-avatar size="40" class="mr-5">
                <v-img
                    :src="$store.state.user.photoUrl === 'null' ? require('../assets/profile.png') : $store.state.user.photoUrl"
                    lazy-src="../assets/profile.png"
                    :alt="$store.state.user.firstName +' '+ $store.state.user.lastName"
                />
              </v-avatar>
              {{ $store.state.user.firstName }} {{ $store.state.user.lastName }}
            </v-btn>
          </v-col>
          <v-col cols="3" v-show="!$store.state.nav.mini">
            <v-btn icon @click="$store.state.nav.mini = true">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-show="$store.state.nav.mini">
        <v-avatar size="30">
          <v-img
              :src="$store.state.user.photoUrl === 'null' ? require('../assets/profile.png') : $store.state.user.photoUrl"
              lazy-src="../assets/profile.png"
              :alt="$store.state.user.firstName +' '+ $store.state.user.lastName"
          />
        </v-avatar>
      </v-container>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-card flat>
            <v-card-text>
              <v-row >
                <v-col cols="12" >
                    <v-img
                        :src="$store.state.user.photoUrl === 'null' ? require('../assets/profile.png') : $store.state.user.photoUrl"
                        lazy-src="../assets/profile.png"
                        :alt="$store.state.user.firstName +' '+ $store.state.user.lastName"
                        style="border-radius: 50%"
                        max-height="256px"
                        max-width="256px"
                        class="mx-auto"
                    />
                </v-col>
                <v-col cols="12">
                  <h1 class="text-center">{{ $store.state.user.firstName }} {{ $store.state.user.lastName }}</h1>
                </v-col>
                <v-col cols="12">
                  <p class="text-center">{{ userTitle }}</p>
                  <p class="text-center text-caption">{{$i18n.t("UID")}}: {{ $store.state.user._id }}</p>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <p>
                    <v-icon>mdi-email</v-icon>
                    {{ $store.state.user.email }}
                  </p>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <p>
                    {{$i18n.t("Verified")}}: {{ $store.state.user.emailValidated ? 'Yes' : 'No' }}
                  </p>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <p>
                    <v-icon>mdi-plus</v-icon>
                    {{ $i18n.t('Join Date') }}
                  </p>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <p>
                    {{ new Date(Number($store.state.user.joinDate)).toLocaleString() }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-btn color="red" dark block @click="Logout">{{ $i18n.t('Logout') }}</v-btn>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import token from "@/utils/token";

export default {
  name: "UserDialog",
  components: {},
  data() {
    return {
      dialog: false,
      userTitle: '',
      email: '',
      pwd: '',
      loading: false,
      profilePhoto: null
    }
  },
  methods: {
    Logout: function () {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('id')
      location.reload()
    },
    setProfilePhoto(photo){
      this.profilePhoto = photo;
    }
  },
  created() {
    let auth = token.getAuthority();
    this.userTitle = auth.indexOf('admin') !== -1 ? 'Administrator' : (auth.indexOf('teacher') !== -1 ? 'Teacher' : auth[auth.length - 1])
  },
  watch: {
    "$store.state.user": {
      deep: true,
      handler: function (){
        let auth = token.getAuthority();
        this.userTitle = auth.indexOf('admin') !== -1 ? 'Administrator' : (auth.indexOf('teacher') !== -1 ? 'Teacher' : auth[auth.length - 1])
      }
    }
  }
}
</script>

<style scoped>

</style>
