<template>
  <v-row justify="center">
    <v-dialog
        v-model="changePasswordDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{$i18n.t("Update your password")}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="New password"
                    type="password"
                    required
                    v-model="password"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Repeat password"
                    type="password"
                    required
                    v-model="repeatPassword"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="changePasswordDialog = false"
          >
            {{$i18n.t("Close")}}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="changePassword"
          >
            {{$i18n.t("Save")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn @click="changePasswordDialog = true" v-if="$store.state.user.status" block class="primary"
           height="56">
      <v-icon left>mdi-lock-reset</v-icon>
      {{$i18n.t("Update Password")}}
    </v-btn>
  </v-row>
</template>

<script>
import User from "@/utils/User";

export default {
  name: "UpdatePassword",
  data() {
    return {
      changePasswordDialog: false,
      password: "",
      repeatPassword: "",
    }
  },
  methods: {
    changePassword() {
      User.updatePassword(this.password)
      this.changePasswordDialog = false
    }
  }
}
</script>

<style scoped>

</style>
