<template>
  <v-dialog v-model="dialog" width="600" persistent v-if="!$store.state.user.status">
    <template v-slot:activator="{ on, attrs }">
      <v-list>
        <v-list-item v-bind="attrs" v-on="on" link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $i18n.t('Not logged') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $i18n.t('Click login') }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </template>
    <LoginForm/>
  </v-dialog>
</template>

<script>
import LoginForm from "@/components/LoginForm";
export default {
  name: "LoginDialog",
  components: {LoginForm},
  data() {
    return {
      dialog: false
    }
  },
}
</script>
<style scoped>

</style>
