<template>
  <div>
    <v-container v-if="!$store.state.user.status" class="pt-16">
      <v-row justify="center">
        <v-img src="../assets/no-access.svg" max-width="200px" max-height="200px"/>
        <v-col cols="12">
          <div class="text-center pt-6 text-h4 text--darken-1">{{ $i18n.t("Please Login First!") }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-banner elevation="6" dark icon="mdi-account" v-else-if="!auth">{{ $i18n.t("Access Denied") }}</v-banner>
    <template v-else>
      <v-card flat class="pa-5">
        <v-row>
          <v-col cols="12" lg="4" md="5" sm="10">
            <h1>{{ $i18n.t("Member Manager") }}</h1>
          </v-col>
          <v-spacer/>
          <v-col cols="12" lg="2" md="2" sm="12">
            <v-select :items="searchParams" :label="$i18n.t('Search By')" v-model="searchBy"/>
          </v-col>
          <v-col cols="12" lg="5" md="5" sm="12">
            <v-row>
              <v-col cols="8" lg="0" md="0" sm="0">
                <v-select :items="authorities" v-model="search" v-if="searchBy === 'auths.authorities'"/>
                <v-select :items="['Yes', 'No']" v-model="search" v-else-if="searchBy === 'paid'" placeholder="Yes"/>
                <v-row v-else-if="searchBy === 'lastActivity'">
                  <v-col>
                    <v-select :items="searchCompares" v-model="searchCompare" :placeholder="$i18n.t('Over')"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="search" single-line hide-details :placeholder="$i18n.t('Days')"/>
                  </v-col>
                </v-row>
                <v-select :items="courseList" item-text="name" item-value="_id" v-model="search"
                          v-else-if="searchBy === 'course'" multiple/>
                <v-text-field
                    v-model="search"
                    :label="$i18n.t('Search')"
                    single-line
                    hide-details
                    v-else
                />
              </v-col>
              <v-col cols="1" lg="0" md="0" sm="0">
                <v-btn large icon @click="doSearch" class="mx-auto ma-2">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" lg="0" md="0" sm="0">
                <v-btn large icon @click="exportCsv" class="mx-auto ma-2">
                  <v-icon>mdi-database-export</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" lg="0" md="0" sm="0">
                <v-btn large icon @click="exportAllCsv" class="mx-auto ma-2">
                  <v-icon>mdi-file-export</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" lg="0" md="0" sm="0">
                <v-btn large icon @click="openNotificationDialog" class="mx-auto ma-2">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-data-table
                v-model="selected"
                :headers="headers.filter(item=>item.invisible!==true)"
                :items.sync="userList"
                class="elevation-6"
                :loading="loading"
                loading-text="Loading... Please wait"
                :options.sync="options"
                item-key="userDetails._id"
                show-select
                :server-items-length="totalItems"
                :sort-by.sync="sortBy"
                :sort-desc.sync="desc"
                :footer-props="{
                  itemsPerPageOptions: [20, 50, 100],
                }"
            >
              <template v-slot:item.loginDetails.authorities="{ item }">
                <v-chip-group>
                      <v-chip v-for="i in item.loginDetails.authorities" :key="i" :color="getAuthColor(i)" :ripple="false">{{i}}</v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.loginDetails.lastActivity="{ item }">
                <div v-if="item.loginDetails.lastActivity">
                  {{ new Date(item.loginDetails.lastActivity).toLocaleString() }}
                </div>
              </template>
              <template v-slot:item.userStates.courses="{ item }">
                <v-menu offset-y v-if="item.userStates.courses && item.userStates.courses.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        text
                    >
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="i in item.userStates.courses" :key="i._id">
                      <v-list-item-title>{{ i.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div v-else></div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        text
                    >
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-icon style="color: #2196f3">
                        mdi-eye
                      </v-icon>
                      <v-btn
                          @click="checkUserDetail(item.userDetails)"
                          text
                          block
                      >
                        <span>{{ $i18n.t("Check Details") }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-icon>
                        mdi-cog
                      </v-icon>
                      <v-btn
                          @click="openUserSettings(item.userDetails._id)"
                          text
                          block
                      >
                        <span>{{ $i18n.t("User Settings") }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-icon>
                        mdi-ticket-confirmation
                      </v-icon>
                      <v-btn
                          @click="openUserContentSetting(item.userStates)"
                          text
                          block
                      >
                        <span>{{ $i18n.t("Token Settings") }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-icon>
                        mdi-chart-areaspline
                      </v-icon>
                      <v-btn
                          @click="openUserStatistic(item.userStates)"
                          text
                          block
                      >
                        <span>{{ $i18n.t("Statistic") }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-icon>
                        mdi-chat
                      </v-icon>
                      <v-btn
                          @click="openChat(item.userStates)"
                          text
                          block
                      >
                        <span>{{ $i18n.t("Chat") }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <AdminUserSettings v-if="this.$store.state.viewingAccountSettings"/>
      <CheckUserDetails v-if="this.$store.state.viewingUserDetails"/>
      <AdminTokenSettings v-if="this.$store.state.viewingTokenSettings"/>
      <UserStatistic v-if="this.$store.state.viewingUserStatistic"/>
      <v-dialog v-if="chatstart" v-model="chatstart" @close="chatstart = false" max-width="800">
        <ChatDialog :receiverId="chatwith"/>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import CheckUserDetails from "@/components/CheckUserDetails";
import AdminUserSettings from "@/components/AdminUserSettings";
import user from "@/utils/User";
import token from "@/utils/token";
import store from "@/store";
import AdminTokenSettings from "@/components/AdminTokenSettings";
import CSV from "@/utils/CSV";
import course from "@/utils/Course";
import User from "@/utils/User";
import i18n from "@/i18n";
import UserStatistic from "@/components/UserStatistic.vue";
import snackBar from "@/utils/SnackBar";
import ChatDialog from "@/components/ChatDialog.vue";

export default {
  name: "MemberManager",
  components: {ChatDialog, UserStatistic, AdminTokenSettings, CheckUserDetails, AdminUserSettings},
  data: () => ({
    userDetails: null,
    userAccount: null,
    page: 1,
    totalItems: 10,
    itemsPerPage: 10,
    options: {},
    auth: false,
    text: '',
    search: '',
    searchBy: '',
    searchCompare: '',
    sortBy: 'loginDetails.lastActivity',
    desc: true,
    userList: [],
    selected: [],
    dialogDelete: false,
    loading: true,
    courseList: [],
    sendNotificationDialog: false,
    checkStatistic: false,
    chatstart: false,
    chatwith: '',
    notification: {
      title: '',
      content: '',
      receivers: []
    },
    searchCompares: [
      {text: i18n.t("In"), value: 'gt'},
      {text: i18n.t('Over'), value: 'lt'},
    ],
    searchParams: [
      {text: i18n.t("First Name"), value: 'firstName'},
      {text: i18n.t('Last Name'), value: 'lastName'},
      {text: i18n.t('Authority'), value: 'auths.authorities'},
      {text: i18n.t('Phone'), value: 'phoneNumber'},
      {text: i18n.t('Email'), value: 'email'},
      {text: i18n.t('Tokens'), value: 'token'},
      {text: i18n.t('Course'), value: 'course'},
      {text: i18n.t('Active Time'), value: 'lastActivity'},
    ],
    headers: [
      {text: i18n.t("First Name"), value: 'userDetails.firstName', align: 'start'},
      {text: i18n.t('Last Name'), value: 'userDetails.lastName', align: 'start'},
      {text: i18n.t('Authority'), value: 'loginDetails.authorities', align: 'start', sortable: false},
      {text: i18n.t('Phone'), value: 'userDetails.phoneNumber', align: 'start'},
      {text: i18n.t('Email'), value: 'userDetails.email', align: 'start'},
      {text: i18n.t('Last Active Time'), value: 'loginDetails.lastActivity', align: 'start'},
      {text: i18n.t('Tokens'), value: 'userStates.token', align: 'start'},
      {text: i18n.t('Course'), value: 'userStates.courses', align: 'start', sortable: false},
      // {text: i18n.t('Paid'), value: 'paid', align: 'start', sortable: false},
      {text: i18n.t('Actions'), value: 'actions', sortable: false, filterable: false},
    ],
    editedIndex: -1,
    editedItem: {
      serverName: '',
      serverIp: '',
      serverDomain: '',
    },
    defaultItem: {
      serverName: '',
      serverIp: '',
      serverDomain: '',
    },
    authorities: ["normal", "admin", "teacher", "god"]
  }),

  computed: {},

  watch: {
    options: {
      handler() {
        user.getUserListAdmin(this, this.searchBy, this.search, this.searchCompare)
      },
      deep: true,
    },
    // selected: {
    //   handler(){
    //     console.log(this.selected)
    //   },
    //   deep: true
    // },
    searchBy: {
      handler() {
        this.search = ''
      },
      deep: true
    }
  },

  created() {
    this.checkAuthority('admin')
    user.getUserListAdmin(this)
    course.getAllCourse(100000, 0, res => {
      this.courseList = res.entities
    })
  },

  methods: {
    setContent(text) {
      this.notification.content = text
    },
    checkAuthority(requiredAuth) {
      let auths = token.getAuthority()
      this.auth = auths.indexOf(requiredAuth) !== -1
    },
    getAuthColor(auth){
      if (auth === 'admin'){
        return 'orange'
      }else if (auth === 'teacher'){
        return 'green'
      }else if (auth === 'god'){
        return 'red'
      }
    },
    doSearch() {
      user.getUserListAdmin(this, this.searchBy, this.search, this.searchCompare)
    },
    getTitle() {
      let auth = token.getAuthority();
      return auth.indexOf('admin') !== -1 ? 'Administrator' : (auth.indexOf('teacher') !== -1 ? 'Teacher' : auth[auth.length - 1])
    },
    exportCsv() {
      CSV.exportAsCsv(this.selected, this.searchBy + "-" + this.search + "-" + this.selected.length + "-" + new Date().getTime())
    },
    exportAllCsv(){
      User.exportCSVServer(this, this.searchBy, this.search, this.searchCompare, ()=>{
        snackBar.Launch("Exported")
      })
    },
    checkUserDetail(item) {
      this.$store.state.viewingUserDetails = item
    },
    openUserSettings(userId) {
      user.getUserAccountDetails(userId, (res) => {
        this.$store.state.viewingAccountSettings = res
      })
    },
    openUserContentSetting(item) {
      this.$store.state.viewingTokenSettings = item
    },
    openUserStatistic(item){
      this.$store.state.viewingUserStatistic = item
    },
    openChat(item) {
      this.chatstart = true
      this.chatwith = item._id
    },
    openNotificationDialog() {
      this.$router.push({name: 'Members Notification', query: {receivers: this.selected.map(each => each.userDetails._id)}})
    },
    sendNotification(notification) {
      for (const i in this.selected) {
        notification.receivers.push(this.selected[i].userDetails._id)
      }
      User.sendNotification(notification, ()=>{})
      this.sendNotificationDialog = false
    }
  },
  mounted() {
    store.state.loading = false;
  },
}
</script>

<style scoped>
#no-background-hover::before {
    background-color: transparent !important;
}
</style>
